
.price {
    .strike-through {
        text-decoration: line-through;
        // color: $grey4;
        color: #767676;

        + .pricing {
            margin-left: 0.75rem;
        }
    }

    .starting,
    .range,
    .sales {
        font-weight: bold;
    }

    .tiered {
        color: $grey7;

        table {
            border-top: 1px solid $grey3;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $grey2;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
            font-weight: bold;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }
}

.cart-items{
    .price-before-discount{
            display: none;
    }   
}

.totals{
    .coupons-and-promos{
        &.lineitem-coupon{
            text-align: left;
            margin-top: 10px;
            letter-spacing: normal;
            width: 100%;
        }
    }
    .coupon-promotion-relationship li{
        &:not(:last-child) {
            display: none;
        }
    } 
    .lineitem-coupon{
        .coupon-price-adjustment{
            display: none;
        }
    }
}