@import "../utilities/swatch";

//Common PDP style shared between PDP and QuickView.
@import "../product/pdpStyle";
@import "../product/pdpSwatches";
@import "../product/pdpShare";
@import "../product/pdpInfo";
//@import "../product/pdpRecommendations";
//@import "../product/pdpTabs";
//@import "../product/pdpFabricFinder";
//@import "../product/pdpDiscover";

.attribute {
    margin-top: 0.938em;
    margin-right: 25%;
    padding: 0;

    label {
        display: block;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 50;
    padding-right: 0;
    padding-left: 0;
    left: 0;

    @include media-breakpoint-down(xs) {
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }

    @include media-breakpoint-up(sm) {
        position: static;
        padding: 0 0.9375em;
    }

    .price {
        text-align: center;
    }
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}

.cart-and-ipay {
    text-align: center;

    @include media-breakpoint-only(xs) {
        .btn {
            width: 100%;
            // margin: 1%;
            display: block;
        }
    }
}

.simple-quantity {
    margin-top: 1em;
}

.main-attributes {
    margin-top: 1em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}

.product-registry {
    .button_text {
        margin-top: 0 !important;
    }
}
