@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";

.minicart {
    position: relative;

    .card-body {
        padding: 0.625em;
    }

    .quantity-label {
        font-size: 0.813em;
    }

    .quantity {
        width: 100%;
    }

    .popover {
        top: 100%;
        left: auto;
        max-width: initial;
        min-width: initial;
        min-height: 6.25rem;
        padding-top: .7rem;
        display: none;
        background-color: transparent;

        @include media-breakpoint-up(md) {
            right: 0;
        }

        @include media-breakpoint-down(sm) {
            right: -1rem;
        }

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            display: block;
        }
    }

    .minicart-footer {
        border-top: 1px solid $grey3;
    }

    .line-item-divider {
        margin: 0.625em -0.625em 0.625em -1.325em;
    }

    .line-item-name {
        width: 90%;
    }

    @media (min-width: 768px) {

    }

    @media (min-width: 1280px) {

    }

    @media (min-width: 1920px) {

    }
}

.minicart-total {
    position: relative;
}

.navigation__minicart {
    .minicart-link {
        text-decoration: none;
    }
    .navigation__utility-icon {
        display: block;
        width: auto;

        .minicart-item {
            display: flex;
            align-items: center;

            .minicart-quantity {
                font-family: $helveticaneue;
                font-size: 11px;
                margin-top: 5px;
            }
        }
    }
}

.minicart-footer__actions {
    margin-top: 1rem;
    .cart-checkoutBtn {
        .apple-pay-cart {
            display: none;
        }

        .btn {
            background-color: $darkgreen;
            border-color: $darkgreen;
        }
    }
}

.hide-link-med {
    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {
    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.minicart-item-oos {
	color: #a60000;
	font-size: 0.65rem;
	font-weight: 500;
	margin-top: 10px;
}

.data-checkout-stage,
.history-card,
.order-confirmation {
	.minicart-item-oos {
		display: none;
	}
}


.navigation__minicart {
    .navigation__utility-icon {
        @media (max-width: 1279.98px) {
            padding: 0;
            justify-content: flex-end;
            width: auto;
            height: auto;
        }
    }
}
