.product--size__box {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}


.pdp--swatches {
    @include flexrow;
    position: relative;
    flex-wrap: wrap;
    margin: 0;

    .color {
        position: absolute;
        left: calc(100% + 1.8125rem);
        top: .125rem;
        width: 100%;
    }

    .swatch--anchor-color {
        margin-left: .625rem;
        margin-right: .625rem;

        &:not(:nth-child(-n + 5)) {
            margin-top: 1.25rem;
        }
    }

    .swatch__selected {
        span:last-child {
            pointer-events: none;
        }
    }

    .swatch__not-selected {
        display: none;
    }
}

.product--related__swatches {
    .pdp--swatches {
        .swatch--anchor-color {
            margin-bottom: 20px;

            &:not(:nth-child(-n + 5)) {
                margin-top: 0;
            }
        }
    }
}

.size-anchor__list {
    @include flexrow;
    line-height: 1.5;
    align-items: baseline;
}

    .size-anchor__value {
        margin-right: 1.5rem;
        cursor: pointer;
        font-size: 0.75rem;
        font-family: $archivo;
        letter-spacing: 1.8px;
        padding-bottom: .5rem;
        border-bottom: solid 1px transparent;

        &:hover,
        &.size-selected {
            border-bottom: solid 1px #979797;
            text-decoration: none;
        }
    }

.size-unavailable {
    color: #cacaca;
}

.size-chart,
.size-anchor__list {
    margin-top: 2rem;
}

.pdp--color__available {

    select {
        display: none;
    }
}

.swatch-circle {
    // $white will be replaced by color image background in ISML
    @include swatch(1.5rem, $white);
    -webkit-box-shadow: inset 0 0 0 0.0625rem rgba(0, 0, 0, 0.40);
    box-shadow: inset 0 0 0 0.0625rem rgba(0, 0, 0, 0.40);
    &.isoutofstock {
	    overflow: hidden;

    	&:before {
    		content: '';
		    width: 2px;
		    height: 100%;
		    background: #000;
		    position: absolute;
		    left: 50%;
		    transform: rotate(45deg) translateX(-50%);
		    margin: 1px 0 0;
    	}
    }
}

.swatch--anchor {
    &[disabled],
    &:not([href]) {
        opacity: 0.35;
        // pointer-events: none;
        cursor: not-allowed;
    }
}

.size-selected {
	cursor: default;
	pointer-events: none;
}

.selecteddisplayvalue {
	display: block !important;
}

.disable-selected {
	cursor: default;
	pointer-events: none;
}

// a:focus, div:focus, span:focus {
// 	outline: none;
// }
.pdp--wrapper {
.pdp--swatches {
    .color {
        font-family: 'Archivo-Thin';
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 0.48px;
        text-align: left;
        color: $black;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            font-size: 12px;
            line-height: 2.58;
            letter-spacing: 0.36px;
        }
        @media (min-width: 1920px) {
            font-size: 16px;
            line-height: 1.94;
            letter-spacing: 0.48px;
        }
    }

    .swatch-circle {
        width: 24px;
        height: 24px;
        border-radius: 0;
        box-shadow: none;

        &.color-value {
            &.selected {
                &::after {

                    border: none;
                    border-bottom: 1px solid $black;
                    border-radius: 0;
                    box-shadow: none;
                    top: 11px;
                    @media (min-width: 1920px) {
                        top: 11px;
                    }
                }
            }
        }
        &.isoutofstock {
            overflow: inherit;
            &:before {
                content: '';
                width: 1px;
            }
        }
    }
    .size-anchor__value {
        font-family: 'Archivo-ExtraLight';
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.36px;
        text-align: left;
        color: $shark;
        margin-right: 10px;
        padding-bottom: 0;
        &:hover {
            border-bottom: none;
        }
        &.size-selected {
            border-bottom: 1px solid #707070;
            @media (min-width: 1280px) {
                border-bottom: 1px solid $black;
            }
        }
    }
}

.size-chart {
    margin-top: 0;

    @media (min-width: 1280px) {
        position: relative;
        top: 0;
        z-index: 1;
    }
    @media (min-width: 1920px) {
        top: 48px;
    }
    &.size-chart-open {
        @media (min-width: 1280px) {
            z-index: auto;
        }
    }

    .size-chart-modal-link {
        //opacity: 0.4;
        font-family: 'Archivo-Thin';
        font-size: 16px;
        font-weight: 100;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 0.48px;
        text-align: left;
        color: $black;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            font-family: 'Archivo-Light';
            font-weight: 300;
            font-size: 14px;
            line-height: 2.21;
            letter-spacing: 0.42px;
        }
        @media (min-width: 1920px) {
            font-size: 16px;
            line-height: 1.94;
            letter-spacing: 0.48px;
        }

        .size-chart-icon {
            margin-left: 3px;
        }
    }
}

.hover-swatch-state {
    @media (min-width: 1280px) {
    position: absolute;
    bottom: 37px;
    left: -40px;
    display: none;
    padding: 0 3px 3px;
    border: solid 0.5px #aaa;
    width: 103px;
    height: 85px;
    border-radius: 3px;
    z-index: 10;
    background-color: $white;
    }
    @media (min-width: 1920px) {
        width: 133px;
        height: 109px;
        bottom: 52px;
        left: -48px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 45px;
        bottom: -6px;
        width: 11px;
        height: 11px;
        background: $white;
        z-index: -1;
        border-left:0.5px solid #aaa;
        border-bottom:0.5px solid #aaa;
        transform:rotate(-45deg);
        @media (min-width: 1920px) {
            left: 58px;
            bottom: -8px;
            width: 14px;
            height: 14px;
        }
    }
    &.swatch-hover-show {
        display: inline !important;
    }
}
.hover-swatch-image {
    width: 97px;
    height: 61.1px;
    border-radius: 3px;
    margin-top: -13px;
    @media (min-width: 1920px) {
        width: 125px;
        height: 78px;
        margin-top: -7px;
    }
}
.hover-swatch-text {
    font-family: 'Archivo-Thin';
    font-size: 11px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.82;
    letter-spacing: 0.33px;
    text-align: center;
    color: $black;
    //margin-top: -5px;
    position: relative;
    top: -6px;
    white-space: nowrap;
    @media (min-width: 1920px) {
        font-size: 12px;
        line-height: 2.58;
        letter-spacing: 0.36px;
        top: -3px;
    }
}
}

// #notifyModal .pdp--swatches .swatch--anchor:not(.swatch__selected),
// #notifyModal .size-anchor__value:not(.size-selected) {
//     display: none !important;
// }
// #notifyModal .pdp--swatches .swatch__selected,
// #notifyModal .size-selected {
//     display: inline-block !important;
// }
.modal-dialog .modalColor,
.modal-dialog .modalSizes{
    font-family: 'Archivo-Thin';
    font-size: 16px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: 0.48px;
    text-align: left;
    color: #000;
    padding-bottom: 0;
}

.modalColor img{
    border-radius: 6px;
}

.modalColor span{
    display: block;
    width: 24px;
    height: 24px;
    margin: 10px 0 24px;
}

.modalColor span:after{
    content: '';
    display: block;
    border-bottom: 1px solid #000;
    margin-top: 10px;
}
