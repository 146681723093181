@import "collapsibleItem";

footer {
    background-color: $white;
    padding-top: 1.25em; /* 20/16 */
    padding-bottom: 1.25em;

    h3 {
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: 2.5em; /* 40/16 */
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .social {
        h3 {
            margin-top: 0;
        }

        @include clearfix;
    }

    .copyright,
    .social {
        margin-top: 1.25em;
    }

    .social-links {
        @include clearfix;

        @include media-breakpoint-down(xs) {
            width: 80%;
        }

        float: left;

        li {
            float: left;
            margin: 0.313em;

            @include media-breakpoint-down(xs) {
                width: 20%;
                text-align: center;
            }
        }

        a {
            font-size: 2.25em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .store {
        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h3 {
                @include clearfix;

                &::after {
                    font-family: "FontAwesome";
                    float: right;
                    content: "\f041";
                }
            }
        }
    }

    .content {
        font-size: 0.875em;

        li {
            height: 1.875rem;
        }
    }

    .copyright,
    .postscript {
        font-size: 0.8125em;
    }

    .copyright {
        margin-bottom: 0.625em;
    }
}

.footer-item {
    @include media-breakpoint-down(xs) {
        border-bottom: 1px solid $dark-gray;
    }

    h3 {
        cursor: pointer;
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.invalid-email-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #c00;
}

.invalid-emailformat-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #c00;
}

#onlineOutletError {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #c00;
}
.global-footer {
    padding: 0;
    .footer-content {
        max-width: 100%;
    }
    .footer-container {

        @media (min-width: 1224px) {

        }
    }
    .heading--4 {
        @media (min-width: 375px) and (max-width: 768px) {
            font-size: 1.3rem;
        }
        @media (min-width: 300px) and (max-width: 374.97px) {
            font-size: 1.1rem;
        }
    }
    .footersignup {
        .form-group:not(.custom-control) {
            margin-bottom: 30px;
            #footer-form-email {
                width: 346px;
                height: 48px;
                @media only screen and (min-width: 768px) {
                    width: 354px;
                    height: 53px;
                }
                text-overflow: ellipsis;
                text-transform: uppercase;
                font-size: 10px;
                letter-spacing: 1px;
                width: 354px;
                height: 53px;
                background-color: $bluecharcoal;
                border-radius: 0;
                border-color: $darkgreen;
                padding-left: 30px;
            }
        }
        .custom-checkbox {
            label[for="receiveEmail"],
            label[for="acceptPrivacyPolicy"] {
                font-size: 10px;
                font-weight: bold;
                line-height: 1.9;
                letter-spacing: 1px;
                color: $white;
                &:before {
                    border: 1px solid $white;
                    background: $darkgreen;
                    border-radius: 0px;
                }
            }
            .custom-control-input:checked ~ .custom-control-label:after {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            }
        }
        .newsletter-submit-button {
            height: 48px;
            background-color: $bluewhale;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 0;
            border-color: $darkgreen;
            margin: 30px 0;
            @media (min-width: 1224px) {
                width: 180px;
                height: 53px;
            }
        }
    }
    .accordianheader {
        flex-wrap: wrap;
        padding: 80px 20px 0;
        @media only screen and (min-width: 768px) {
            display: flex;
            padding: 0 15px;
            justify-content: space-between;
        }
    }
    .footer-item {
        border: 0;
    }
    .footeraccordian {
        .footer-custheader {
            font-family: $archivo;
            font-size: 12px;
            font-weight: 500;
            line-height: 2.67;
            letter-spacing: 1px;
            text-transform: uppercase;
            width: 100%;
            text-align: left;
            &.active > .footer-dropdown {
                transform: rotateX(159deg);
                padding-bottom: 8px;
            }
            .footer-dropdown {
                float: right;
                font-size: 20px;
                &:before {
                    vertical-align: -webkit-baseline-middle;
                }
                @media only screen and (min-width: 768px) {
                    display: none;
                }
            }
            &.active + .menu-footer {
                display: block;
            }
        }
        .menu-footer {
            opacity: 0.8;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
        }
    }
    .copyright-notice {
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0;
            padding-left: 6px;
        }
    }
    .footer-contactcall {
        opacity: 0.7;
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 1px;
        padding: 48px 0 25px;
        text-align: center;
        @include media-breakpoint-up(xl) {
            padding: 48px 0 80px;
        }
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
        a {
            text-decoration: underline;
            font-weight: 700;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .footer-topcontent {
        text-align: center;
        background-color: $white;
        color: $darkgreen;
        border-top: 1px solid $grey10;
        height: 100%;
        box-shadow: 3px 45px 100px 0 rgba(0, 0, 0, 0.11);
        .footer-factnum {
            padding-top: 40px 0 15px;
            font-family: $butler;
            font-size: 16px;
            line-height: 3.5;
            letter-spacing: 0.3px;
            color: $darkgreen;
        }
        .footer-history {
            font-family: $archivo;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: 0.8px;
            padding: 0 0 35px 0;
        }
    }
    .footer-image {
        font-size: 0;
        display: block;
    }
}

.show-on-767 {
    display: none !important
}
@media screen and (max-width: 767px) {
    .show-on-767 {
        display: block !important
    }
    .hide-on-767 {
        display: none !important
    }
}

.footer-instagram-1, .footer-facebook-1, .footer-pinterest-1, .footer-wechat-1 {
    @media (max-width: 767.98px) {
        display: none;
    }

    @media (min-width: 1920px){
        display: none;
    }
}

.footer-instagram-2, .footer-facebook-2, .footer-pinterest-2, .footer-wechat-2 {
    @media (min-width: 768px) and (max-width: 1919.98px){
        display: none;
    }
}

.footer-instagram, .footer-facebook, .footer-pinterest, .footer-wechat{
    width: 26px;
    height: 26px;
}

.global-footer {
    .accordianheader {
        padding: 31.8px 0 0px;
        width: 100%;
        @media (min-width: 768px) {
            padding: 0;
        }
        @media (min-width: 1280px) {
           width: 52.9%;
        }
        @media (min-width: 1920px) {
            width: 49.4%;
        }

        .collapsible-item {
            .content {
                @media (max-width: 767.98px) {
                    display: none;
                }
            }
        }


    }

    .footeraccordian {
        button.footer-custheader {
            margin-bottom: 0;
            &.active {
                margin-bottom: 20px;
            }
        }
        .menu-footer { /*added-new*/
                font-family: $helveticaneue;
                font-size: 14px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 125%;
                letter-spacing: .28px;
                text-align: left;
        }
        .footer-custheader {
            .footer-plus {
                float: right;
                @media (min-width: 768px) {
                    display: none;
                }
            }

            .footer-minus {
                display: none;
            }

            &.active {
                .footer-plus {
                    display: none;
                }
                .footer-minus {
                    float: right;
                    @media (max-width: 767.98px) {
                        display: block;
                    }
                }
            }
        }
    }

    .footer-item-social {
        li {
            display: inline;
        }
        a {
            &:hover {
                text-decoration: none;
            }
        }

        img {
            margin-right: 11px;
            @media (min-width: 1920px) {
                margin-right: 17px;
            }
        }
        .footer-wechat-1, .footer-wechat-2 {
            margin-right: 0;
        }
        .menu-footer {
            @media (min-width: 768px) {
                text-align: right;
                margin: 0;
            }
            @media (min-width: 1280px) {
                text-align: left;
            }
            @media (min-width: 1920px) {

            }
        }

        .accordianheader {
            padding-bottom: 0;
        }

        .footeraccordian {
            @media (max-width: 767.98px) {
                padding-bottom: 0;
            }

            @media (min-width: 1280px) {
                padding-bottom: 0;
            }

            .menu-footer {
                @media (max-width: 767.98px) {
                    margin-bottom: 13px;
                }
            }
        }
    }

    .footer-copyright {
        font-family: $helveticaneue;
        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: 0.24px;
        text-align: left;
        color: $boulder;
        padding: 16px;
        margin-top: 32px;
    }

    .footer-copyright-notice {
        width: 100%;
    }

    .footer-notice-social {
        @media (max-width: 767.98px) {
            flex-direction: column-reverse;
        }
    }
}

.global-footer {
    .radio-option-wraper {
        label {
            color: #fff;
            opacity: .7;
            font-size: 12px;
        }
    }
}

//H By Frette Footer
.h-by-frette-footer {

    .h-by-frette-footer__logo {
        width: 48px;
        margin-bottom: 16px;

        @media (min-width: 1280px) {
            margin-top: 48px;
        }

        img {
            width: 100%;
        }
    }

    .h-by-frette-footer__description {
        font-family: $helveticaneue;
        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: 0.24px;
        text-align: left;
        color: $rollingstone;
        margin-bottom: 8px;
        @media (min-width: 768px) {

        }
        @media (min-width: 1920px) {

        }

    }

    .h-by-frette-footer__cta {
        font-family: $helveticaneue;
        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: 0.24px;
        text-align: left;
        color: $shark;
        padding: 8px 0;
        border-bottom: 1px solid $shark;
        @media (min-width: 768px) {

        }
        @media (min-width: 1920px) {

        }
    }

    .h-by-frette-footer__cta-openlink {
        width: auto;
        margin-bottom: 4px;
        margin-left: 3px;
        @media (min-width: 768px) {
            margin-bottom: 3px;
        }

        @media (min-width: 1920px) {
            margin-bottom: 4px;
        }
    }
}

//Added to enable slick pinch zoom
.slick-slider {
    touch-action: auto !important;
}

//New redesign styles
.email-signup-footer {
    background-color: $secondary;
    padding: 48px 16px;

    @media (min-width: 1280px) {
        padding: 48px 160px;

        form {
            display: flex;
        }

        h2.footer--subscribe__form--header {
            margin-right: 96px;
        }
    }

    .optional-radio {
        color: $white;
    }

    h2.footer--subscribe__form--header {
        font-family: $didot;
        font-size: 24px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        color: $white;
        margin-bottom: 36px;
    }

    .email-input {
        display: block;
        position: relative;

        .footer--subscribe__form--input {
            position: relative;
            font-family: $helveticaneue;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            line-height: 52px;
            text-align: left;
            background: rgba(255, 255, 255, .1);
            border: 1px solid $white;
            padding: 0 104px 0 24px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 4px;
            outline: none;
            margin: 0;
            color: $white;

            &::placeholder {
                color: $white;
            }

            @include media-breakpoint-down(vs) {
                height: 66px;
            }
            &::placeholder { /* Chrome/Opera/Safari/Firefox */
                white-space: pre-line;
                position: relative;
                line-height: 1;
                top: 50%;
                right: 104px;
                left: 24px;
                transform: translateY(-50%);
                position: absolute;
            }
        }
    }
    .subscribe-modal__email {
        display: block;
        position: absolute;
        top: 0;
        right: 0;

        .footer--subscribe__form--button {
            width: auto;
            height: auto;
            opacity: 1;
            border-radius: 0;
            background: none;
            font-family: $helveticaneue;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: .28px;
            text-align: center;
            color: $white;
            padding: 8px 0 4px;
            margin: 16px 24px 16px 0;
            border-bottom: 1px solid $white;

            @include media-breakpoint-down(vs) {
                margin: 22px 23px 22px 0;
            }
        }
    }
    #footer_newsletter {
        position: relative;
        width: 100%;
    }
    #footer_newsletter.input-focus,
    #footer_newsletter.input-populated {
        .email-input {
            .footer--subscribe__form--input {
                background-color: $white;
                color: $shark;
            }
        }
        .subscribe-modal__email {
            .footer--subscribe__form--button {
                color: $shark;
                border-color: $shark;
            }
        }
    }

}
.footer-item-social {
    display: none;

    &.mobile {
        @media (max-width: 767px) {
            display: block;
        }
    }
    &.desktop {
        @media (min-width: 768px) {
            display: block;
        }
    }
}
.footer-container {
    padding: 0 16px;
    margin: 0;
    margin-top: 48px;
    display: block;

    @media (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
    }

    .row {
        margin: 0;
        padding: 0;
        display: block;

        .accordianheader {
            padding: 0;
            margin: 0;

            .menu-footer {
                padding-top: 4px;
                margin: 0;
                margin-bottom: 20px;

                li.footer-links {
                    height: auto;
                    padding: 4px 0;

                    a {
                        color: $rollingstone;

                        &:hover {
                            color: $shark;
                        }
                    }
                }
            }
        }
    }

    .footer-brand {
        width: 100%;
        text-align: center;
        display: block;

        @media (min-width: 1280px) {
            width: 25%;
        }
    }
    .footer-logo {
        display: inline-block;
        width: 200px;
        height: 115px;

        @media screen and (max-width: 1279.9px) {
            margin-bottom: 32px;
        }
    }
    .footer-item-social {
        width: 100%;
        text-align: left;

        .menu-footer.content {
            padding: 16px 0;
        }
    }

    .footer-columns {
        @media (min-width: 1280px) {
            width: 50%;

            .accordianheader {
                width: 100%;

                .footer-item {
                    width: 33%;
                    padding-right: 24px;
                }
            }
        }
    }

    .footer-connect {
        @media (min-width: 1280px) {
            width: 25%;
        }
    }
}
.global-footer .footer-container h2.footer-custheader,
.global-footer .footer-container .footeraccordian button.footer-custheader {
    font-size: 12px;
    font-family: $helveticaneue;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: .12px;
    padding: 12px 0;
    border-bottom: 1px solid $silver;
    margin-bottom: 12px;
    position: relative;
    text-transform: uppercase;

    @media (min-width: 1280px) {
        padding-bottom: 0;
        border: none;
    }
}

.global-footer .footer-container .footeraccordian button.footer-custheader:after {
    background-image: $svg-arrow-down;
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    content: "";
    background-repeat: no-repeat;
    transform: translateY(-50%);
}
.global-footer .footer-container .footeraccordian button.footer-custheader.active:after {
    background-image: $svg-arrow-up;
}

.js-back-to-top {
    transition: .4s;
    opacity: 0;
    display: none;
    position: fixed;
    right: 1.5rem;
    bottom: 4.5rem;
    width: 36px;
    height: 36px;
    border: 1px solid $mercury;
    border-radius: 100%;
    background-color: $white;
    background-image: url(../images/chevron-up.svg);
    background-position: center center;
    background-size: contain;
    z-index: 10;
    cursor: pointer;

    &.show {
        display: block;
    }

    &.opaque {
        opacity: 1;
    }

    @media (min-width: 1280px) {
        width: 44px;
        height: 44px;
        right: 3.25rem;
    }
}
