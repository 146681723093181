/*------------------------------------*\
  #ICONS - Mapped to the static svg directory file names
\*------------------------------------*/

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;

    //In case the .icon class is not added directly on the svg element.
    svg {
        width: 100%;
        height: 100%;
        fill: inherit;
    }
}

.icon--small {
    width: 0.5rem;
    height: 0.5rem;
}

.icon--medium {
    width: 1.125rem;
    height: 1.125rem;
}

.icon--large {
    width: 2.5rem;
    height: 2.5rem;
}

.icon--rotate-h {
    //Mirror and not rotate. Rotation can cause a slightly off rendering.
    transform: scaleX(-1);
}

.icon--rotate-up {
    transform: rotate(90deg);
}

.icon--rotate-down {
    transform: rotate(-90deg);
}

.icon--set-left {
    margin-right: .6em;
}

.icon--set-left-short {
    margin-right: .35em;
}

.icon--set-right {
    margin-left: .6em;
}

.icon--set-right-short {
    margin-left: .35em;
}

.icon--inline-align-center {
    vertical-align: middle;
}

/*------------------------------------*\
  #ANIMATED-ICONS
\*------------------------------------*/

.line-animation {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: .05;
    pointer-events: none;

    &.set--pos-top {
        top: 20%;
    }

    &.set--pos-bottom {
        bottom: 20%;
    }

    &.set--style-wide {
        width: 150%;
    }
}

    .line-animation__path {
        fill: none;
        stroke: $black;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s linear forwards;

        &.set--no-animation {
            display: none;
        }
    }

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}