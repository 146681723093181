
//Minicart styles

.minicart{
    .popover{
        width: 100vw;
        border: 0;

        @include media-breakpoint-up(md) {
            max-width: 500px;
        }

        .cart-miniCart{
            padding-top: 1.5rem;
            background-color: $white;
            border: 1px solid $grey10;
        }

        .minicart-header {
            margin-bottom: 20px;
        }

        .minicart-header,
        .minicart-error,
        .minicart-contents {
            padding-right: 1.875rem;
            padding-left: 1.85rem;
        }

        .minicart-contents {
            overflow: auto;
            max-height: 12.75rem;
            -webkit-overflow-scrolling: touch;
            
            @media (max-width: 768px) and (orientation: landscape){
                max-height: -webkit-calc(100vh - 68px - 4rem - 135px);
				max-height: -moz-calc(100vh - 68px - 4rem - 135px);
				max-height: calc(100vh - 68px - 4rem - 135px);
            }
            
            
        }

        .card{
            margin-bottom: 1.25rem;
            border:0;
        }

        .card-body{
            padding: 0;
        }
        .product-line-item-details{
            position: relative;
            width: 100%;
            overflow: initial;
            flex-wrap: wrap;
        }

        .item-image{
            width: 122px;
            margin-right: 0;
        }

        .item-details {
            flex: 1 1 50%;
            margin-left: 1.25rem;
        }

        .line-item-name{
            white-space: unset;
            font-size: 16px;
            font-family: $butler;
            letter-spacing: 0.7px;
            color: $darkgreen;
            letter-spacing: 0.5px;
        }
        .remove-line-item{
            position: absolute;
            top:0;
            right:0;
            .remove-product{
                background: none;

                .icon {
                    fill: black;
                }
            }
        }
        .item-attributes {
            line-height: 1.8;
            letter-spacing: 0.5px;
            color: $matterhorn;

            .line-item-attributes{
                font-size: 10px;
            }
        }
        .minicart-price-details {
            flex-basis: 100%;
            text-align: center;
            font-weight: normal;
            line-height: 1.8;
            letter-spacing: 0.5px;
            padding-left: 12px;
            flex-basis: auto;
            padding-left: 0;
            margin-right: 0;
        }
        .line-item-total-price{
            max-width: 100%;
            padding: 0;
        }
        .price {
            display: flex;
            justify-content: flex-end;
            line-height: 1.8;
            letter-spacing: 0.5px;
            color: $matterhorn;

            .line-item-total-price-amount{
                font-size: 10px;
            }
        }

        .minicart-footer{
            padding: 1rem 1.875rem 2rem;
            border-top: none;
            background-color: $grey1;
        }
    }
    .price-before-discount {
        font-size: 10px;
    }
}
