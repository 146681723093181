@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.add-to-wishlist-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 15%;
    left: 50%;
    z-index: 9999;
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    @media (min-width: 1280px) {
        visibility: hidden;
    }

    i {
        &.fa-heart-o {
            color: rgba(0, 0, 0, 0.7);
        }

        &.fa-circle {
            color: rgba(255, 255, 255, 0.7);
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
    }

    span {
        &.fa-stack {
            position: absolute;
            top: 9px;
            right: 16px;
            font-size: 1.2em;
            border-radius: 50px;
        }
    }
}

.wishlistTile {
    .fa-heart {
        color: black;
    }
}

.wishlist-wrapper {
    &:not(.login-page) {
        padding-left: 0;
        padding-right: 0;
    }
}

.fabric-finder-products{
    .wishlistTile {
        span {
            &.fa-stack {
                @media (min-width: 1280px) {
                    top: 10px;
                    right: 5px;
                }
                @media (min-width: 1920px) {
                    top: 15px;
                    right: 10px;
                }
            }
        }
    }
}

.product-grid-tile {
    .wishlistTile {
        span {
            &.fa-stack {
                @media (min-width: 1280px) {
                    top: 3px;
                    right: -3px;
                }
                @media (min-width: 1920px) {
                    top: 6px;
                    right: 0px;
                }
            }
        }
    }
}

.fabric-finder-products-plp{
    .wishlistTile {
        span {
            &.fa-stack {
                @media (min-width: 1280px) {
                    top: 5px;
                    right: 5px;
                }
                @media (min-width: 1920px) {
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }
    .wishlist-tile-hover {
        display: inline-block;
    }
}