/**
 * FixIt base styles. These can be overriden if necessary.
 */

.fixit-container {
	position: relative;
}

.fixit-element {
	&.fixit--active {
		position: fixed;
		transform: translate3d(0,0,0); //Fixes iOS flicker/lag.
        color: $black;
		top: 0;

		&:not(.fixit--respond-to-parent) {
			width: 100%;
		}
	}

	&.fixit--bottom,
	&.fixit--docked {
		top: initial;
		bottom: 0;
	}

	&.fixit--frozen,
	&.fixit--docked {
		position: absolute;
	}
}

//Adds higher z-index when the fixed element should overlay others.
.fixit-element--overlay {
	z-index: 1010;

	&:not(.transparent-header),
	&.fixit--scrolled {
		background-color: $white;
	}
}
.fixit--active .header-banner {
	transition: 0.25s;
	height: 0 !important;
}
.fixit--active .navigation {
    padding-top: 0px;
}
.fixit--active .header-banner .container .content {
    height: 0;
}