.subscribe-modal {
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    right: inherit;
    max-width: 826px;
    width: 100%;
    bottom: 0;
	height: auto;

    @include media-breakpoint-down(sm) {
        left: 0;
        transform: none;
        top: inherit;
        animation: bottom-to-zero 3s normal; 
    }
}

.subscribe-modal--container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.subscribe-modal__img {
    min-width: 319px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.subscribe-modal__information {
    background: $white;
    min-width: 507px;

    @include media-breakpoint-down(sm) {
        min-width: 100vw;
        background: $darkgreen;
    }
}

.subscribe-modal__close {
    padding-top: 0.625rem;
    padding-right: 2rem;
    width: 40px;
    height: 40px;
    float: right;
    cursor: pointer;
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
        color: $white;
    }
}

.subscribe-modal__form {
    padding-left: 3.5rem;
    padding-right: 2.75rem;

    @include media-breakpoint-down(sm) {
        padding-bottom: 4rem;

        .text-color--darkgreen,
        .text-color--matterhorn {
            color: $white;
        }
    }

    @include media-breakpoint-down(xs) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 2rem;

        .body-type--centi {
            font-size: 1.5rem;
            padding-bottom: 1rem;
        }
    }
}

.subscribe-modal__title {
    padding-top: 3.5rem;

     @include media-breakpoint-down(xs) {
        padding-top: 1rem;
     }
}

.subscribe-modal__term {
    position: relative;
    @include flexrow;
    align-items: baseline;
}

 .subscribe-modal__label {
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    padding-left: 1.5rem;
    position: relative;
    color: $grey81;

    a {
        text-decoration: underline;
        cursor: pointer;
    }


    @include media-breakpoint-down(xs) {
        font-size: 0.625rem;
        margin-top:0;
    }
}

 .subscribe-modal__label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: 40% 0;
    background-size: 60% 80%;
}

 .subscribe-modal__label:before {
    background: $white;
    border-radius: 0;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;

     @include media-breakpoint-down(sm) {
        border-color: $white;
     }
}

.subscribe-input__hidden:focus~.subscribe-modal__label::before {
		outline: 1px dotted $grey3;
}

.subscribe-input__hidden {
    opacity: 0;
    z-index: 2;
    position: absolute;
}

.subscribe-input__hidden:checked~.subscribe-modal__label:after {
    background-image: $svg-check-white;
}

.subscribe-modal__email {
    @include flexrow;
    flex-wrap: nowrap;
}

.subscribe-modal__input {
    width: 100%;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.subscribe-modal__btn{
    padding: 0.5rem 2rem;
    color: $white;   
    
    @include media-breakpoint-down(xs) {
        padding: 0 0.625rem;
    }
}
  
.subscribe-modal__btn {

    background: $darkgreen;

    border: solid 1px $darkgreen;

    @include media-breakpoint-down(sm) {
        border-color: $white;
    }
}

@keyframes bottom-to-zero {

    0% { bottom: -30vh; 
    }

    100%  { bottom: 0; 
    }
}

.subscribe-msg--error, .subscribe-msg--error_email,
.subscribe-msg--error-marketing, .subscribe-msg-thankyou, .subscribe-msg-subscribed  {
    font: 10px/20px $archivoLight;
    margin-top: 5px;
    letter-spacing: 0.9px;

     @include media-breakpoint-down(sm) {
        color: $white;
    }
}

.subscribe-msg-thankyou {
    color: $white;
    font-size: 14px;
}

.subscribe-msg--error_email, .subscribe-msg--error {
    color: $coupon-warning-red !important;
}

.subscribe-msg--error-marketing {
    color: $coupon-warning-red !important;
}
// FOOTER
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}
.subscribe-footer__input {
    // background: $bluecharcoal;
    background: #596a7b;
    width: 100%;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border: none;
    color: $white;
    margin: 0;
    // margin-top: 1.5rem;

    &::-webkit-input-placeholder { color: $white; }
    &::-moz-placeholder { color: $white; }
    &:-ms-input-placeholder { color: $white; }
    &:-moz-placeholder { color: $white; }
}

.subscribe-footer__btn {
    background: $bluewhale;
    border: $bluewhale;
    padding: 0.5rem 2rem;
    color: $white;

        @include media-breakpoint-down(sm) {
        border-color: $bluewhale;
    }
}

.footer--subscribe__term {
    position: relative;
    @include flexrow;
    align-items: baseline;

    @include media-breakpoint-down(xs) {
        padding-top: 1.25rem;
        padding-bottom: 1rem;
    }
}

.footer--subscribe__form--header {
    font-family: $didot;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 125%;
    color: $white;

    @media (min-width: 768px) {
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: 0.98px;
    }
    @media (min-width: 1280px) {
    
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 1.4px;
    }
}

.footer--subscribe__form--input {
    // opacity: 0.4;
    font-family: 'Archivo-Light';
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: 0.6px;
    text-align: left;
    background-color: transparent;
    border-bottom: 1px solid rgba(129, 142, 151, 1);
    margin-top: 16.4px;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;

    &::placeholder {
        color: $grey81;
    }

    @media (min-width: 768px) {
        font-family: 'Archivo-ExtraLight';
        font-size: 23px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.74;
        letter-spacing: 0.46px;
        margin-top: 20.7px;
    }
    @media (min-width: 1280px) {
    
    }
    @media (min-width: 1920px) {
        font-size: 35px;
        line-height: 0.74;
        letter-spacing: 0.7px;
        margin-top: 24px;
    }
}

.footer--subscribe__form--label {
    font-family: $helveticaneue;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 125%;
    letter-spacing: .24px;
    text-align: left;
    margin: 20px 0 0 0;
    padding: 4px 0 0 24px;
    color: $white;
    width: 100%;

    @media (min-width: 768px) {
        
    }
    @media (min-width: 1280px) {
        
    }

    @media (min-width: 1920px) {
        
    }
}

.footer--subscribe__form--label:before {
    border: 1px solid #fff;
    background: none;
    width: 16px;
    height: 16px;
    opacity: 1;
    margin: 2px 0;
}

.footer--subscribe__form--button {
    width: 100%;
    height: 60px;
    opacity: 0.7;
    border-radius: 8px;
    background-color: $white;

    font-family: 'Archivo-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.11px;
    text-align: center;
    color: $black;

    @media (min-width: 768px) {
        width: 169px;
        height: 41px;
        border-radius: 6px;

        font-size: 9px;
        line-height: 0.56;
        letter-spacing: 1.58px;
    }
    @media (min-width: 1280px) {
        width: 151px;
        height: 37px;
        border-radius: 6px;
    }
    @media (min-width: 1920px) {
        width: 225px;
        height: 60px;
        border-radius: 8px;

        font-size: 12px;
        line-height: 1;
        letter-spacing: 2.11px;
    }
}

.footer--subscribe__term {
    padding: 0;
}

.subscribe-input__hidden:checked~.subscribe-modal__label:after {
    top: 4px;
    right: 5px;
}
