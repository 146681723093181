.carousel {
    .icon-prev,
    .icon-next {
        background-color: $white;
        font-size: 1.875em;
        // width and height here need to use rem units because the font size used here is 30px
        height: 3rem;
        padding-top: 0.24em;
        width: 3rem;

        &::before {
            color: black;
            font-family: 'FontAwesome';
        }
    }

    .icon-prev {
        &::before {
            content: '\f104';
        }
    }

    .icon-next {
        &::before {
            content: '\f105';
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
    }

    .carousel-control-next {
        justify-content: flex-end;
    }
}

.nav-tabs {
    border-bottom: $border-width solid $grey3;

    @include media-breakpoint-up(md) {
        .nav-item {
            margin-right: 2.5rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .nav-item {
            margin-right: 1.5rem;
        }
    }

    .nav-link {
        padding: 0;
        color: $nav-tabs-link-hover-border-color;

        &.active {
            border-bottom: 0.188em solid $primary;
        }
    }
}

.card {
    margin-bottom: 1em;
}

.card-header h4 {
    margin-bottom: 0;
}

.modal .modal-body {
    flex: 0 0 auto;
}

dt {
    color: $gray-700;
    font-weight: normal;
}

.custom-checkbox .custom-control-label::before {
    // border: 1px solid $grey3;
    border: 1px solid #71767c;
    background-color: transparent;
}

.custom-checkbox .custom-control-input {
    &:checked ~ .custom-control-label::before {
        background-color: transparent;
    }

    &:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem $grey15;
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: $svg-check;
}

.custom-radio .custom-control-label::before {
    border: 1px solid black;
    background: $grey3; /* For browsers that do not support gradients */
    background: linear-gradient($grey3, $grey5); /* Standard syntax */
}

.modal-open {
    position: fixed;
    width: 100%;
}

.popover {
    font-family: inherit;
}

.container {
    @include media-breakpoint-up(md) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
    }

    @include media-breakpoint-down(sm) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.container--large-gutter {
    @include media-breakpoint-up(xl) {
        max-width: 90rem;
        padding-right: 5.875rem;
        padding-left: 5.875rem;
    }

    @include media-breakpoint-between(lg, lg) {
        padding-right: 4rem;
        padding-left: 4rem;
    }
}

.list-unstyled {
    margin: 0;
}

a, a:hover {
    color: inherit;
}

p {
    margin-bottom: 0;
}

.mobile-modal-open {
    @media screen and (max-width: 1279px) {
        position: fixed;
        width: 100%;
    }
}
