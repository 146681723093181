@import "bootstrap/scss/mixins/breakpoints";

.pdp--product__information {
    margin-top: 3rem;
    
    @include media-breakpoint-down(sm) {
		margin-top: 1rem;
	}
}

.pdp--product--details {
    position: relative;

    &:not(:last-child) {
        margin-bottom: 2.75rem;
		}
		&.accordion-active {
			.product--details__container{
				max-height: 800px;
				opacity: 1;
		    perspective: 900;
				transform: translate( 0 , 0 );
			}
		}

    .accordion-opener {
        font-size: 0.75rem;
        letter-spacing: 1.3px;
        font-family: $archivo;
        color: $darkgreen;
        display: block;

        &:hover {text-decoration: none;}
    }

    .transition{
        transition: all 0.25s ease-in-out
    }

    .flipIn{
        animation: flipdown 0.5s ease both;
    }

    i {
        position: absolute;
        top: 6px;
        right: 0;
        z-index: 1;
        &:focus {
            outline: 2px solid #ccc;
        }
    }

    input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        top: 8px;
        right: 1px;
        z-index:2;
        opacity: 0;

        &:checked {
            &~i {
					-moz-transform:rotate(180deg);
					-webkit-transform:rotate(180deg);
					transform:rotate(180deg);
            }
        }
    }

    .product--details__container {
        position: relative;
        padding-bottom: 2.875rem;
        padding-top: 18px;
        z-index: 2;
        padding: 0;
				overflow: hidden;
				margin-top: 0;
				max-height: 0;
				opacity: 0;
				transform: translate( 0 , 50% );
        transition: all 0.25s ease-in-out;

        p:first-child{
            margin-top: 1.125rem;
        }
    }

    p, 
    .dimension-container .dimension-list {
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.5px;
        color: $matterhorn;
    }
    
    .dimension-container {
    	margin-top: 15px;
    	.dimension-list {
    		padding-left: 15px;
    		margin-bottom: 12px;
    	}
    }

    &:not(.accordion-active) .product--details__container {
        display: none;
    }
}

.product-view-more {
    margin-top: 1rem;
}

.promotions {
    margin-left: 1.5rem;
    letter-spacing: 0.5px;

    .callout {
        font-size: .625rem;

        &:not(:last-child) {
            margin-bottom: .25rem;
        }
    }
}
.product-availability-col{
    padding-right: 0;
}
[data-key="credit-promotion-small"] {
    &::part(osm-container) {
        padding: 4px;
        border: 0;
        text-align: center;
        max-width: 100%;
        min-height: 100%;

        @include media-breakpoint-up(lg) {
            padding: 13px 9px;
            text-align: left;
        }
    }
}

.pdp--product--details {
    .dimension-container {
        .dimension-list {
            font-family: 'Archivo-ExtraLight';
            font-size: 16px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.48px;
            text-align: left;
            color: $black;
            padding-left: 0;
            margin-bottom: 0;
            @media (min-width: 768px) {
            
            }
            @media (min-width: 1280px) {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.42px;
            }

            @media (min-width: 1920px) {
                font-size: 16px;
                line-height: 1.56;
                letter-spacing: 0.48px;
            }    
        }
    }
    &.accordion-active {
        .product--details__container {
            padding-bottom: 5px;
            @media (min-width: 768px) {
                padding-bottom: 10px;
            }
            @media (min-width: 1280px) {
            }
            @media (min-width: 1920px) {
            
            }    
        }
    }
    p, .pdp-detail-description, .oeko-tek-text{
            font-family: 'Archivo-ExtraLight';
            font-size: 16px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.48px;
            text-align: left;
            color: $black;

            @media (min-width: 768px) {
            
            }
            @media (min-width: 1280px) {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.42px;
            }

            @media (min-width: 1920px) {
                font-size: 16px;
                line-height: 1.56;
                letter-spacing: 0.48px;
            }    
    }

    .accordion-opener {
        
        .accordian-open, .accordian-close {
            float: right;
        }
        .accordian-open {
            margin-right: -10px;
        }
        .accordian-open {
            display: inline-block;
        }
        .accordian-close {
            display: none;
        }
        &.active {
            .accordian-open {
                display: none;

            }
            .accordian-close {
                display: inline-block;
            }
        }
    }

    /*.pdp-shipping__guides {
        border-bottom: 0.5px solid #dbdbdb;
        padding-bottom: 10px;
        &:empty {
            border-bottom: none;
            padding-bottom: 0;
        }  
    }*/
        .accordion-opener, .product-dimens, .pdp-detail-heading {
            font-family: 'Archivo-Light';
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 0.48px;
            text-align: left;
            color: $black;
            @media (min-width: 768px) {
            
            }
            @media (min-width: 1280px) {
            }
            @media (min-width: 1920px) {
                font-size: 20px;
                line-height: 1.55;
                letter-spacing: 0.6px;
            }
        }

        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 0 !important;
        border: none;
        @media (min-width: 768px) {
                        
        }
        @media (min-width: 1280px) {
        }
        @media (min-width: 1920px) {
        
        }
        @supports (-webkit-touch-callout: none) {
            .pdp-care__instructions, .pdp-info__return, .pdp-product__info, .pdp-product__dimensions, .pdp-oekotek {
                box-shadow: 0 0.5px 0 $black;
            }
        }
        .pdp-care__instructions, .pdp-info__return, .pdp-product__info, .pdp-product__dimensions, .pdp-oekotek {
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: none;
            box-shadow: 0 0.5px 0 $black;
            margin-bottom: 0.5px;
            
            @media (min-width: 768px) {
               
            }
            @media (min-width: 1280px) {
                padding-bottom: 7.5px;
                padding-top: 7.5px;
            }
            @media (min-width: 1920px) {
                padding-top: 19px;
                padding-bottom: 19px;
            }
        }
       
        .pdp-info__return {
            .product--details__container {
                span {
                    font-family: 'Archivo-ExtraLight' !important;
                    font-size: 16px !important;
                    @media (min-width: 1280px) {
                        font-size: 14px !important;
                    }
                    @media (min-width: 1920px) {
                        font-size: 16px !important;
                    }

                    a {
                        text-decoration: underline;
                        color: $black !important;
                    }
                }   
            } 
        }
            
        i {
            top: 15px;
        }
} 

.product-klarnaplacement-col {
    padding-left: 0;
    margin-bottom: 10px;
    display: none;
    
    @media (min-width: 1280px) {
        display: block;
        width: 100%;
    }
}

.product-klarnaplacement-col-mobile {
    display: block;
    margin: 20px 0;
    width: 100%;
    @media (min-width: 1280px) {
        display: none;
    }
}
