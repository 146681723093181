// imported file for extra support
@import "../variables";
@import "helpers/pixelToRem";
@import "helpers/updatedBreakpoint";


.navigation__search  {
    width: unset;
    margin-top: 2px;

    .mobile-search-link {
        display: block;
        .icon {
            width: 19.2px;
            height: 24px;
        }
    }

    .site-search {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 20px;
        z-index: 1000;
        background-color: $white;

        @media screen and (min-width: 1280px) {
            padding: 0
        }

        .close-search-flyout {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            margin-bottom: 40px;

            .close-search {
                display: flex;
                width: 15px;
                height: 15px;

                .icon {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .site-search__form  {
            margin-top: 45px;

            label {
                position: absolute; top: 0; right: 5px;
                width: 20.6px; height: 20.6px;
                margin: 0;
                display: flex;

                .search-icon {
                    max-width: 100%;
                    max-height: 100%;
                    fill: $emperor;
                }
            }
        }

    }

    .site-search, .suggestions-wrapper {
        display: none;
    }

    @media (min-width: 768px) {
        width: unset;
    }

    @media (min-width: 1280px) {
        width: 220px;
        margin-top: unset;

        .mobile-search-link {
            display: none;
        }

        .site-search{
            display: block;
            position: relative; top: unset; left: unset;
            width: unset; height: 20px;
            z-index: unset;
            background-color: unset;

            .close-search-flyout  {
                display: none;
            }

            .site-search__form  {
                margin-top: unset;

                label {
                    position: absolute;
                    right: 0;
                    width: 23.9px; height: 23.9px;
                    margin: 0;
                    display: flex;

                    .search-icon {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        .suggestions-wrapper{
            display: block;
        }
    }

    @media (min-width: 1920px) {
        width: 306px;

        .site-search {
            height: 28px;
        }
    }
}


.site-search__form {
    z-index: 3;
    width: 100%;
    margin-bottom: 40px;

    /* clears the 'X' from Internet Explorer */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    &:not(.toggle--active) {
        @include set-invisible();
    }
}

.site-search__field {
    font-family: $helveticaneue;
    font-size: 18px;
    line-height: 1.28;
    color: $black;
    font-stretch: normal;
    letter-spacing: 0.54px;
    text-align: left;
    background-color: transparent;
    outline: 0;
    border: none;
    border-bottom: solid .0625rem $greyDB;
    padding: 2px 32px 6px 0;
    width: 100%;

    @media (min-width: 768px) {
        font-size: 22px;
        line-height: 1.05;
        letter-spacing: 0.66px;
        padding: 2px 32px 6px 0;
    }

    @media (min-width: 1280px) {
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0.28px;
        padding: 0 28px 2px 0;
    }

    @media (min-width: 1920px) {
        padding: 0 34px 4px 0;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $grey;
        opacity: 1;
    }

    &::-webkit-search-cancel-button {
        display: none;
    }
}

.site-search__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
    background-color: transparent;
    border: none;
}

.header__search-trigger {
    position: relative;
    margin: 0;
}

.site-search__suggestions-list {
    width: 100%;
    max-height: 65vh;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border: .0625rem solid $grey3;
    background-color: $white;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.site-search__suggestions-section {
    &:not(:last-child) {
        margin-bottom: 1.25rem;
    }
}

.site-search__suggestions-title {
    margin-bottom: .5rem;
}

.site-search__suggestions-item {
    &:not(:last-child) {
        margin-bottom: .75rem;
    }
}

.site-search__suggestions-image-wrap {
    overflow: hidden;
    @include aspect-ratio(1, 1, true);
}

.site-search__suggestions-image {
    max-height: none;
    @include vertical-align(true);
}

.site-search__suggestions-banner {
    margin-bottom: .5rem;
}

@include media-breakpoint-up(md) {
    .site-search__form {
        max-width: 21.875rem;
        right: calc(100% + 1.25rem);

        @include vertical-align(false, true);
    }
}

@include media-breakpoint-down(sm) {
    .site-search__form {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 1.125rem 1rem;
        background-color: $white;
        border-top: solid .0625rem $grey2;
        border-bottom: solid .0625rem $grey2;
    }
}

.suggestions-column--search {
    @include media-breakpoint-only(xl) {
        width: 50vw;
        max-width: 690px;
    }
}

// Override the bootstrap no clear button
.site-search__field::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.custom-item-spacing{
    margin-bottom: 0.5rem !important;
}

.custom-header-spacing-removed{
    margin-bottom: -10px !important;
}

.site-search__form {
    &:not(.toggle--active) {
        @include set-visible();
    }
}

.site-search__form {
    max-width: 304px;

    @media (min-width: 768px) {
        max-width: 520px;
    }
    @media (min-width: 1280px) {
        max-width: 215px;
        right: 0;
    }
    @media (min-width: 1920px) {
        max-width: 306px;
    }
}


.header__search-trigger {
    @media (min-width: 1280px) {
        z-index: 100;
    }
    @media (min-width: 1920px) {
        margin-left:4px;
    }
}

.site-search__form {
    @media (max-width: 1279.98px) {
        border-top: none;
        border-bottom: none;
        top: 0;
        position: relative; // added to remove height issues for search
        margin: auto;
        padding: 0;
        right: 0;
        left: 0;
        transform: none;
    }
}

/* SEARCH SUGGESTION
-------------------- */
/* For search input box for mobile */

.navigation-container {
    .mega--shown {
        .icons-mega-menu {
            display: none !important;
        }
    }
}

.search-show-mobile {
    &.navigation__menu-item {

        &.mega-item--active {
            border: none;
            margin: 0;
            top: 0;
            width: 100%;

            > a.mega-item--active {
                padding: 0;
                margin: 21px 20px 0 20px;
                width: auto;

                .navigation__utility-icon, .search-text {
                    display: none !important;
                }

                .icon-back-menu {
                    position: unset;
                    width:29.7px; height:12.5px;
                }
            }
        }
    }

    @include media-updated-breakpoint-up(md) {
        &.navigation__menu-item {
            &.mega-item--active {
                border: none;
                margin: 0;
                top: 0;
                width: 100%;

                > a.mega-item--active {
                    padding: 0;
                    margin: 31px 31px 0 30px;
                    width: auto;

                    .navigation__utility-icon, .search-text {
                        display: none !important;
                    }

                    .icon-back-menu {
                        position: unset;
                        width: 51.2px; height: 27px;
                    }
                }
            }
        }
    }

}

.site-search, .navigation__search  {
    .reset-close-icon {
        display: none;
        position: absolute; top: 8px; right: 4px; z-index: 4; height: 11px; width: 11px;
        background: url(../../images/icons/search-close-icon.svg) no-repeat 0 0;
        background-size: 11px 11px;
        text-indent: -9999px;
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
            cursor: default;
        }
        [disabled] {
            opacity: 0.5;
            cursor: default;
        }
    }

    @include media-updated-breakpoint-up(md) {
        .reset-close-icon {
            top: 8px; right: 2px;
            height: 12.9px; width: 12.9px;
            background-size: 12.9px 12.9px;
        }
    }

    @include media-updated-breakpoint-up(lg) {
        .reset-close-icon {
            top: 10px;
            height: 8px; width: 8px;
            background-size: 8px 8px;
        }
    }

    @include media-updated-breakpoint-up(xl) {
        .reset-close-icon {
            top: 8px; right: 4px;
            height: 10px; width: 10px;
            background-size: 10px 10px;
        }
    }
}

.suggestions-wrapper {
    position: unset;
    top: unset; left: unset; z-index: unset;
    width: 100vw;
    background-color: $white;

    @media screen and (max-width: 1279px) {
        position: absolute;
        z-index: 1001;
        left: 0;
        top: 145px;
    }

    a, a:hover {
        text-decoration: none;
        margin: 0; padding: 0;
    }

    .searched-product-slider {
        padding: 0;
        margin-right: -20px;

        .slick-list {
            margin: 0;
            padding-right: 9px;
        }
    }

    .suggestions-container {
        padding: 72.8px 20px 77px 20px;
        width: 100vw;

        @media screen and (max-width: 1279px) {
            height: calc(100vh - 145px);
            overflow: auto;
        }

        .searched-category {
            display: flex;
            flex-direction: column;

            .title {
                font: 18px/1.78 $archivoRegular;
                letter-spacing: 0.54px;
                margin-bottom: 21px;
                color: $black;
            }

            .column-left {
                width: 100%;
                display: flex;
                flex-direction: column;

                .column-item {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-right: 0;
                }
            }

            .column-right {
                flex-grow: unset;
                padding: 0;
                width: 100%;

                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .link-txt {
                        font: 18px/2.22 $archivoExtraLight;
                        letter-spacing: 0.54px;
                        text-decoration: underline;
                    }
                }

                .category-slider-area {
                    width: 100%;
                    margin-top: 0;

                    .slider {
                        .slider-img {
                            margin-right: 11px;

                            img {
                                border-radius: 6px;
                            }
                        }
                        .slider-info {
                            width: unset;
                            padding: 19px 19px 0 0;
                            margin-right: 11px;
                            text-align: left;

                            .name {
                                font: 18px/1.28 $archivoLight;
                                letter-spacing: 0.72px;
                                color: $black;
                                padding-bottom: 12.3px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                            .price-range {
                                font: 14px/1.64 $archivoExtraLight;
                                letter-spacing: 0.42px;
                                color: $grey6C;
                            }
                        }
                    }

                    .slick-arrow {
                        &::before {
                            display: none;
                        }
                    }

                    .slick-next {
                        display: none;
                        background: url(../images/icons/right-arrow-icon-black.svg) center center no-repeat;
                        background-size: 100%;
                    }

                    .slick-prev {
                        display: none;
                    }
                }
            }
        }
    }

    .category-list-area {
        text-align: left;
        margin: 0 0 27.8px;
        padding-bottom: 24.8px;
        border-bottom: 0.5px solid #dbdbdb;

        ul {
            list-style: none;
            padding: 0;

            li {
                font: 18px/2.22 $archivoExtraLight;
                letter-spacing: 0.54px;
                color: $grey-6C;
            }
        }
    }

    //@media (min-width: 768px) and (max-width: 1279.99px) {
    @include media-updated-breakpoint-up(md) {
        width: 100vw;
        background-color: $white;

        .searched-product-slider {
            padding: 0;
            margin-right: -34px;

            .slick-list {
                margin: 0;
                padding-right: 138px;
            }
        }

        .suggestions-container {
            padding: 96.8px 32px 103.2px 32px;
            width: 100vw;
            margin: 0;

            .searched-category {
                display: flex;
                flex-direction: column;

                .title {
                    font: 20px/1.6 $archivoRegular;
                    letter-spacing: 0.6px;
                    margin-bottom: 25px;
                }

                .column-left {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .column-item {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding-right: 0;
                    }
                }

                .column-right {
                    flex-grow: unset;
                    padding: 0;
                    width: 100%;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .link-txt {
                            font: 18px/2.78 $archivoExtraLight;
                            letter-spacing: 0.54px;
                        }
                    }

                    .category-slider-area {
                        width: 100%;
                        margin-top: 0;

                        .slider {
                            .slider-img {
                                margin-right: 18px;

                                img {
                                    border-radius: 6px;
                                }
                            }
                            .slider-info {
                                width: 282px;
                                padding: 16px 16px 0 0;
                                margin-right: 18px;
                                text-align: left;

                                .name {
                                    font: 18px/1.28 $archivoLight;
                                    letter-spacing: 0.72px;
                                    padding-bottom: 9.1px;
                                }
                                .price-range {
                                    font: 14px/1.64 $archivoExtraLight;
                                    letter-spacing: 0.36px;
                                }
                            }
                        }

                        .slick-arrow {
                            &::before {
                                display: none;
                            }
                        }

                        .slick-next, .slick-prev {
                            display: none;
                        }
                    }
                }
            }
        }

        .category-list-area {
            text-align: left;
            margin: 0 0 36.8px;
            padding-bottom: 34.8px;
            border-bottom: 0.5px solid #dbdbdb;

            ul {
                list-style: none;
                padding: 0;

                li {
                    font: 18px/2.78 $archivoExtraLight;
                    letter-spacing: 0.54px;
                }
            }
        }
    }

    //@media (min-width: 1280px) and (max-width: 1919.99px) {
    @include media-updated-breakpoint-up(lg) {
        position: absolute; top: calc(100% - -20px); left: -23px; z-index: 3;

        .searched-product-slider {
            padding: 0;
            margin: 0;

            .slick-list {
                margin-right: -12px;
                padding: 0;
            }
        }

        .suggestions-container {
            padding: 31px 24px 86.8px 24px;
            margin: 0;

            .searched-category {
                display: flex;
                flex-direction: unset;

                .title {
                    font: 16px/2 $archivoLight;
                    letter-spacing: 0.48px;
                    margin-bottom: 10px;
                }

                .column-left {
                    width: 38.32%;
                    display: flex;
                    flex-direction: row;

                    .column-item {
                        width: 236px;
                        display: flex;
                        flex-direction: column;
                        padding-right: 69px;
                    }
                }

                .column-right {
                    flex-grow: 1;
                    padding: 0 50px 0 29px;
                    width: calc(100% - 38.32%);

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .link-txt {
                            font: 14px/2.29 $archivoLight;
                            letter-spacing: 0.42px;
                        }
                    }

                    .category-slider-area {
                        width: 100%;
                        margin-top: 20px;

                        .slider {
                            .slider-img {
                                margin-right: 12px;

                                img {
                                    border-radius: 8px;
                                }
                            }
                            .slider-info {
                                width: 219px;
                                padding: 13.5px 20px 0;
                                margin-right: 12px;
                                text-align: center;

                                .name {
                                    font: 16px/1.44 $archivoLight;
                                    letter-spacing: 0.64px;
                                    padding-bottom: 5.9px;
                                }
                                .price-range {
                                    font: 12px/1.92 $archivoExtraLight;
                                    letter-spacing: 0.36px;
                                }
                            }
                        }

                        .slick-arrow {
                            &::before {
                                display: none;
                            }
                        }

                        .slick-next {
                            display: block;
                            width: 9.8px; height: 19.5px;
                            right: -36.6px; top: calc((100% - 85px)/2);
                        }

                        .slick-prev {
                            display: none;
                        }
                    }
                }
            }
        }

        .category-list-area {
            text-align: left;
            margin: 0 0 20px;
            padding-bottom: 0;
            border-bottom: unset;

            ul {
                list-style: none;
                padding: 0;

                li {
                    font: 14px/2.14 $archivoExtraLight;
                    letter-spacing: 0.42px;
                }
            }
        }
    }

    @include media-updated-breakpoint-up(xl) {
        left: -41px;

        .searched-product-slider {
            padding: 0;

            .slick-list {
                margin-right: -18px;
            }
        }

        .suggestions-container {
            padding: 68px 42px;
            margin: 0;

            .searched-category {
                display: flex;
                flex-direction: unset;

                .title {
                    font: 19px/1.68 $archivoLight;
                    letter-spacing: 0.57px;
                    margin-bottom: 10px;
                }

                .column-left {
                    width: 31.6%;
                    display: flex;
                    flex-direction: row;

                    .column-item {
                        width: 290px;
                        display: flex;
                        flex-direction: column;
                        padding-right: 40px;
                    }
                }

                .column-right {
                    flex-grow: 1;
                    padding: 0 60px;
                    width: calc(100% - 31.6%);

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .link-txt {
                            font: 16px/1.44 $archivoLight;
                            letter-spacing: 0.48px;
                        }
                    }

                    .category-slider-area {
                        width: 100%;
                        margin-top: 20px;

                        .slider {
                            text-align: center;

                            .slider-img {
                                margin-right: 18px;

                                img {
                                    border-radius: 8px;
                                }
                            }
                            .slider-info {
                                width: 271px;
                                padding: 21.5px 20px 0;
                                margin-right: 18px;
                                text-align: unset;

                                .name {
                                    font: 16px/1.44 $archivoLight;
                                    letter-spacing: 0.64px;
                                    padding-bottom: 12px;
                                }
                                .price-range {
                                    font: 12px/1.92 $archivoExtraLight;
                                    letter-spacing: 0.36px;
                                }
                            }
                        }

                        .slick-arrow {
                            &::before {
                                display: none;
                            }
                        }

                        .slick-next {
                            display: block;
                            width: 18.6px; height: 37.3px;
                            right: -52px; top: calc((100% - 116.86px)/2);
                        }

                        .slick-prev {
                            display: none;
                        }
                    }
                }
            }
        }

        .category-list-area {
            text-align: left;
            margin: 0 0 20px;
            padding-bottom: 0;
            border-bottom: unset;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font: 19px/2.11 $archivoExtraLight;
                    letter-spacing: 0.57px;
                }
            }
        }
    }
}

.fixit--active {
    @include media-updated-breakpoint-up(lg) {
        .suggestions-wrapper {
            top: calc(100% - 20px);
        }
    }

    @include media-updated-breakpoint-up(xl) {
        .suggestions-wrapper {
            top: calc(100% - 20px);
        }
    }
}

@include media-updated-breakpoint-up(lg) {
    .disabled-menu {
        .navigation__menu  {
            pointer-events: none;
            .navigation__menu-anchor {
                pointer-events: none;
            }
        }

        .menu-overlay {display: block !important;}
    }
}

@include media-updated-breakpoint-up(xl) {
    .disabled-menu {
        .navigation__menu  {
            pointer-events: none;
            .navigation__menu-anchor {
                pointer-events: none;
            }
        }

        .menu-overlay {display: block !important;}
    }
}
