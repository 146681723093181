html {
    font-size: 1rem;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // scroll-behavior: smooth;
}

body {
    min-width: 320px; //Intentional px value to avoid custom font-sizes to override the actual min-width of the site.
    font-family: $archivo;
}

.page{
    background-color: $white;
}

.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

a {text-decoration: none;}
a:hover {text-decoration: underline;}

.grecaptcha-badge {
    visibility: hidden;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.clearfix:before, .clearfix:after {
    display: table;
    content: " ";
}

.clearfix:after {
    clear: both;
}

.off-screen-text {
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		width: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
}

/* ------------------------------------------------------------ *\
	Section Guide
\* ------------------------------------------------------------ */
.section-guide .article h2 {
    margin-bottom: 28px;
text-align: center;
text-transform: uppercase;
letter-spacing: 0.03em;
font-family: "Arapey", serif;
font-weight: normal;
font-size: 25px;
}
.section-guide h3 {
margin-bottom: 23px;
text-align: center;
letter-spacing: 0.075em;
text-transform: uppercase;
font-family: "Arapey", serif;
font-weight: normal;
font-size: 20px;
}
.section-guide { padding-bottom: 30px;
@media (max-width: 767px) {
    margin: 0 -4px;
}
}
.section-guide .section-head {
@media (max-width: 767px) {
}
}
.section-guide .section-actions { margin-bottom: 10px; }
.section-guide .section-actions .btn { font-size: 15px; letter-spacing: 0.09em; }
.section-guide .article { max-width: 470px; margin: 0 auto; letter-spacing: 0.02em;
@media (max-width: 767px) {
    max-width: 100%;
    padding: 0;
    font-size: 9px;
}
}
.section-guide .article h2 { margin-bottom: 28px; text-align: center; text-transform: uppercase; letter-spacing: 0.03em;
@media (max-width: 767px) {
    margin-bottom: 12px;
    font-size: 15px;
}
}

.section-guide h3 { margin-bottom: 23px; text-align: center; letter-spacing: 0.075em; text-transform: uppercase;
@media (max-width: 767px) {
    margin-bottom: 5px;
    font-size: 12px;
}
}

.section-guide .section-body .article p {
    font-size: 13px !important;
}

.section-guide .content .article p {
    font-size: 13px !important;
}
/*  Guide Table  */
.table-container { overflow: hidden; margin-bottom: 55px; text-align: center;
@media (max-width: 767px) {
    margin: 0 0 16px -11px;
    width: 106.3%;
}
}
.section-guide .table { display: inline-block;    /* font-family: "Futura Book", sans-serif; */
width: auto;
margin-bottom: 0;
padding-bottom: 1px;
@media (max-width: 767px) {
    /*width: 100%;*/
}
}
.section-guide .table table { width: auto; font-size: 12px; letter-spacing: 0.03em;
@media (max-width: 767px) {
    /*width: 100%;*/
}
}
.section-guide .table th { position: relative; background: #ececec; vertical-align: middle;}
.section-guide .table strong { /* font-family: 'Futura Book', sans-serif; */ text-transform: uppercase; font-weight:bold; }

.section-guide .table th,
.section-guide .table td {
position: relative; padding: 9px 23px; text-align: center; border-top: 0;
        /*font-family: "Futura Book", sans-serif;*/
@media (max-width: 767px) {
    font-size: 7px;
    padding: 5px 4px;
    letter-spacing: 0.03em;
}
}

.section-guide .table th { padding-top: 12px; padding-bottom: 12px; font-weight: normal; letter-spacing: 0.09em; text-align: center; border: 0 none; text-transform: uppercase;
@media (max-width: 767px) {
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;
    letter-spacing: 0.03em;
}
}
.section-guide .table td { text-transform: none; vertical-align: top; border-bottom: 1px solid #e1e0e0; }
.section-guide .table td:first-child { text-align: left; }

.section-guide .table th:first-child:before,
.section-guide .table th:last-child:after,
.section-guide .table td:first-child:before,
.section-guide .table td:last-child:after { position: absolute; content: ''; width: 1000px;
@media (max-width: 767px) {
    display: none;
}
}

.section-guide .table th:last-child:after,
.section-guide .table td:last-child:after { left: 100%; }

.section-guide .table th:first-child:before,
.section-guide .table td:first-child:before { right: 100%; }

.section-guide .table th:last-child:after,
.section-guide .table th:first-child:before { bottom: 0; top: 0; background: #ececec; }

.section-guide .table td:last-child:after,
.section-guide .table td:first-child:before { bottom: -0.5px; height: 1px; background: #e1e0e0; }

.success-text-form p { color: #5c5c5c; font-size: 10px; letter-spacing: 0.12em; line-height: 2; margin-bottom: 20px; text-transform: uppercase; }
.section-discover .success-text-form, #signUpForm { margin-bottom: 100px; }

.largedesktop-img {
    display: none !important;
    @media (min-width: 1920px) {
        display: block !important;
    }
}
.desktop-img {
    display: none !important;
    @media (min-width: 1280px) and (max-width: 1919.98px) {
        display: block !important;
    }
}
.tablet-img {
    display: none !important;
    @media (min-width: 768px) and (max-width: 1279.98px) {
        display: block !important;
    }
}
.mobile-img {
    display: none !important;
    @media (max-width: 767.98px) {
        display: block !important;
    }
}

.hide-on-mobile {
    @media (max-width: 767.98px) {
        display: none !important;
    }
}

.show-mobile {
    display: none !important;
    @media (max-width: 767.98px) {
        display: block !important;
    }
}

.sticky-nav {
    background: #fff;
    position: sticky;
    top: 60px;
    z-index: 5;
    @media screen and (min-width: 1280px) {
        top: 79px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        list-style: none;
        margin: 0;
        li {
            &:not(:last-child) {
                margin-right: 40px;
            }
            a {
                font-family: 'Archivo-Light';
                opacity: 0.5;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0.48px;
                color: #000;
                display: inline-block;
                padding: 18px 0px;
                &:hover {
                    text-decoration: none;
                    opacity: 1;
                }
                @media screen and (min-width: 768px) {
                    font-size: 12px;
                    padding: 23px 0px;
                    letter-spacing: 0.36px;
                }
                @media screen and (min-width: 1280px) {
                    font-size: 14px;
                    padding: 21px 0px;
                    letter-spacing: 0.42px;
                }
                @media screen and (min-width: 1920px) {
                    font-size: 16px;
                    padding: 35px 0px;
                    letter-spacing: 0.48px;
                }
            }
            &.active {
                a {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .slick-slide {
            width: 200px;
        }
        ul {
            padding: 0;
            li a {
                display: block;
                text-align: center;
                // padding: 18px 0px;
            }
        }

        .slick-arrow {
            width: 5px;
            height: 8px;
            background: url(../../images/icons/leftarrow-giftguide.svg) center center no-repeat;
            background-size: cover;
            left: calc((100% - 200px)/2);
            &.slick-next  {
                left: auto;
                right: calc((100% - 200px)/2);
                transform: rotate(180deg);
            }
        }
    }

    &.sticky {
        border-bottom: solid 1px #ededed;
        border-top: solid 1px #ededed;
    }

    .slick-arrow.slick-disabled {
        display: none !important;
    }
}

.no-promotion {
    .sticky-nav {
        top: 105.5px;
        @media screen and (max-width: 1279.98px){
            top: 59.5px;
        }
        @media screen and (min-width: 1920px) {
            top: 143px;
        }
    }
}

.image-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video-modal {
    padding-right: 0 !important;
    .close {
        position: absolute;
        right: 21px;
        top: 21px;
        z-index: 5;
        opacity: 1;
        width: 15px;
        height: 16px;
        line-height: 0;
        svg {
            width: 100%;
            height: 100%;
        }
        @media screen and (min-width: 768px) {
            width: 22px;
            height: 22px;
            right: 20px;
            top: 20px;
        }
        @media screen and (min-width: 1280px) {
            width: 16px;
            height: 16px;
            right: 25px;
            top: 25px;
        }
        @media screen and (min-width: 1920px) {
            width: 23px;
            height: 23px;
            right: 33px;
            top: 33px;
        }
    }
    .modal-dialog {
        max-width: 100%;
        height: 100vh;
        margin: 0;
    }
    .modal-content {
        border: 0;
        background: #f2f2f2;
        min-height: 100%;
        justify-content: center;
    }
    .modal-body {
        padding: 60px 21px;
        .media {
            position: relative;
            // max-width: 332px;
            width: 100%;
            padding-bottom: 57%;
            margin: auto;
            background: #000;
            @media screen and (max-width: 767.98px) {
                max-width: 100%;
            }
            @media screen and (min-width: 768px) {
                max-width: 728px;
                padding-bottom: 57.2%;
            }
            @media screen and (min-width: 1280px) {
                max-width: 1109px;
                padding-bottom: 42.3%;
            }
            @media screen and (min-width: 1920px) {
                max-width: 1538px;
                padding-bottom: 46.7%;
            }
        }
    }
}

.modal.fade {
    display: none !important;
    &.show {
        display: block !important;
    }
}

.keep-exploring {
    padding-top: pixelToRem(50);
    padding-bottom: pixelToRem(76);
    text-align: center;
    @media screen and (min-width: 768px) {
        padding-bottom: pixelToRem(103);
    }
    @media screen and (min-width: 1280px) {
        padding-bottom: pixelToRem(125);
    }
    @media screen and (min-width: 1920px) {
        padding-bottom: pixelToRem(62);
    }
    .container {
        max-width: 100%;
        padding: 0 pixelToRem(20);
        @media screen and (min-width: 1280px) {
            padding: 0 pixelToRem(25);
        }
        @media screen and (min-width: 1920px) {
            padding: 0 pixelToRem(38.5);
        }
    }
    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: pixelToRem(17);
        margin: 0;
        > div {
            padding: 0;
            max-width: 100%;
            position: relative;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: 768px) {
            grid-gap: pixelToRem(20);
            grid-row-gap: pixelToRem(50);
        }
        @media screen and (min-width: 1280px) {
            grid-gap: pixelToRem(16.7);
            grid-row-gap: 0;
        }
        @media screen and (min-width: 1920px) {
            grid-gap: pixelToRem(25);
        }
    }
    h3 {
        font-family: 'Archivo-ExtraLight';
        color: #000;
        font-size: pixelToRem(20);
        line-height: 1.55;
        letter-spacing: pixelToRem(3);
        margin: 0 0 pixelToRem(43) 0;
        text-transform: uppercase;
        @media screen and (min-width: 768px) {
            font-size: pixelToRem(16);
            line-height: 1.94;
            letter-spacing: pixelToRem(2.4);
            margin: 0 0 pixelToRem(74) 0;
        }
        @media screen and (min-width: 1280px) {
            font-size: pixelToRem(20);
            line-height: 1.55;
            letter-spacing: pixelToRem(3);
            margin: 0 0 pixelToRem(56) 0;
        }
        @media screen and (min-width: 1920px) {
            margin: 0 0 pixelToRem(85) 0;
        }
    }
    h4 {
        font-family: 'Archivo-ExtraLight';
        color: #000;
        font-size: pixelToRem(16);
        line-height: 1.94;
        letter-spacing: pixelToRem(.48);
        margin: 0;
        a {
            color: inherit;
            &:hover {
                text-decoration: none;
            }
        }
        @media screen and (min-width: 768px) {
            font-size: pixelToRem(16);
        }
        @media screen and (min-width: 1280px) {
            font-size: pixelToRem(20);
            line-height: 1.55;
            letter-spacing: pixelToRem(0.6);
        }

    }
    .image {
        position: relative;
        border-radius: pixelToRem(8);
        overflow: hidden;
        margin: 0 0 pixelToRem(6) 0;
        padding-bottom: 100%;
        @media screen and (min-width: 768px) {
            margin: 0 0 pixelToRem(21) 0;
        }
        @media screen and (min-width: 1280px) {
            margin: 0 0 pixelToRem(16.6) 0;
        }
        @media screen and (min-width: 1920px) {
            margin: 0 0 pixelToRem(25) 0;
            padding-bottom: 99.1%;
        }
    }
}

.cover-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.scroll-wrapper {
    max-height: 250px;
    @media screen and (max-width: 1279.98px) {
        max-height: 200px;
    }
    .mCSB_scrollTools {
        .mCSB_draggerContainer {
            right: -11px;
            margin: 8px 0;
        }
        .mCSB_dragger .mCSB_dragger_bar {
            background-color: #c1c1c1 !important;
            opacity: 1;
            border-radius: 0;
        }
        .mCSB_draggerRail {
            background: #f1f1f1;
            border-radius: 0;
            width: 4px;
        }
    }
}

/*END*/


.skip-link {
	position: absolute;
    left: 0;
    top: -100%;
    background: #000;
    color: #fff;
    z-index: 999;
    padding: 15px 18px;
    display: inline-block;
    font-size: 15px;
	&:focus {
		top: 4px;
		z-index: 9999;
	}
    &:hover {
		color: #fff;
		text-decoration: underline;
	}
}

.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    padding: 0 !important;
    border: 0 !important;
    white-space: nowrap !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
}

.section-head {
    @media screen and (max-width: 480px) {
        h1 {
            font-size: 24px;
        }
    }
}


.nav-tabs {
    .disable-item {
        cursor: not-allowed;
        a {
            pointer-events: none;
        }
    }
}


.radio-option-wraper {
	.rd-msg-top {
		font-size: 14px;
	    color: #505050 !important;
	    letter-spacing: 0.5px;
	    margin-bottom: 20px;
		text-transform: none;
	    a {
	    	text-decoration: underline;
	    }
	}
	.reg-radio-box {
		margin-bottom: 0;
		label {
			font-size: 14px;
		    color: #505050;
		    letter-spacing: 0.5px;
		    display: inline-block;
    		margin-right: 40px;
    		&:last-child {
    			margin-right: 0;
    		}
    		input[type="radio"] {
    			position: relative;
			    top: 1px;
			    margin-right: 3px;
    		}
		}
	}
	.after-radio-txt {
		font-size: 14px;
	    color: #505050;
	    letter-spacing: 0.5px;
	    margin-bottom: 20px;
	}
	&.fields {
        .reg-radio-box {
            padding: 10px 0 10px 0;
            line-height: normal;
            label {
                font-size: .625rem;
                margin-bottom: 0;
                display: inline-flex;
                align-items: center;
                &:not(:last-child) {
                    margin-right: 20px;
                }

                input[type="radio"] {
                    top: 0;
                }
            }
        }
    }
	&.checkout {
        .reg-radio-box {
            padding: 10px 0 10px 0;
            line-height: normal;
            label {
                font-size: .75rem;
                margin-bottom: 0;
                display: inline-flex;
                align-items: center;
                &:not(:last-child) {
                    margin-right: 20px;
                }

                input[type="radio"] {
                    top: 0;
                }
            }
			.after-radio-txt {
				font-size: .75rem;
			}
        }

		.custom-control-input {
			&.is-invalid {
				& ~ .custom-control-label {
					color: inherit;
					&::before,
					&::after {
						border-color: #ccc;
					}
				}
			}
			& ~ .custom-control-label {
				&::before,
				&::after {
					box-shadow: none;
					transition: none;
				}
			}
		}
    }
	.rd-txt-btm {
		font-size: 14px;
	    color: #505050;
	    letter-spacing: 0.5px;
	    margin-bottom: 20px;
	}
}

body {
    .uwy {
        .userway_buttons_wrapper {
            top: auto !important;
            right: auto !important;
            bottom: 13px !important;
        }
    }
}
