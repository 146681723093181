.adyen-checkout__input:not(.adyen-checkout__input--error) {
    border-color: #71767c !important;
}

.form-group {
    &.required {
        .form-control-label:after {
            content: "*";
        }
    }

    &:not(:last-child) {
        margin-bottom: $form-group-margin-bottom;
    }

    // &:last-child {
    //     margin-bottom: 0;
    // }
}

input.form-control {
    height: 2.375rem;
    border-color: #71767c;
}
textarea.form-control {
    border-color: #71767c; 
}

.form-control-label,
.custom-control-label {
    font-size: .625rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $matterhorn;
}

.form-control-label--spaced {
    margin-bottom: 1rem;
}

.custom-select {
    background-image: url(../../images/angle-arrow-down.svg);
    background-size: .625rem;
    border-color: #71767c;
    option {
    	&:disabled {
    		color: $grey11;
    	}
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem $grey15;
    }
}

.custom-form-field{
    font-size: .750rem !important;
}