html {
    //Certain modals contain forms,
    //which are known to cause scrolling issues on iOS devices
    &.cancel-scroll--force {
        overflow: hidden;
        height: 100%;
    }
}

body {
    .cancel-scroll &{
        overflow: hidden;
    }

    .cancel-scroll--force & {
        overflow: hidden;
        height: 100%;
    }
}

.window-modal {
    z-index: 10000; //Nothing should be higher than this.
    overflow: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($black, 0.3);
    -webkit-overflow-scrolling: touch;
    transition-property: opacity, visibility;

    &.modal-active {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-medium;
    }

    &:not(.modal-active) {
        pointer-events: none;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;
        @include set-invisible();
    }

    // .loader {
    //  background-color: transparentize($black, 0.9);
    // }

    .modal-container--static {
        display: block;
    }

    &.modal-in-background {
        z-index: 9999;
        background-color: transparentize($black, 0.8);
    }
}

    .window-modal__content {
        // z-index: 1;
        position: relative;
        width: 100%;
        min-height: 6.25rem;
        background-color: $white;
        transition-property: top, bottom, right, left, height, transform, opacity, visibility;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;

        .modal-active & {
            transition-duration: $transition-speed-medium;
            transition-timing-function: $transition-ease-out;
        }

        .modal-in-background & > * {
            opacity: .5;
        }
    }

    .window-modal__close {
        z-index: 10;
        position: absolute;
        height: 2.5rem;
        width: 2.25rem;

        &:hover {
            opacity: .5;
        }
        
        &:after {
            content: url(get-icon(close, $black));
            width: 1rem;
            height: 1rem;
            display: inline-block;
        }
    }

/*------------------------------------*\
  #UTILITY-MODAL-CLASSES
\*------------------------------------*/
.modal-container--static {
    display: none;
}

.modal--full {
    .window-modal__content {
        max-width: none;
    }
}

.modal--flex {
    .window-modal__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal--hideable {
    &.modal-in-background {
        @include set-invisible();
    }
}

/*------------------------------------*\
  #GENERIC-MODAL
  Use these if you don't know what else to use.
\*------------------------------------*/

.modal--generic__descr {
    margin-top: .75em;
}

.modal--generic__content {
    margin-top: 2rem;
}

.modal--generic__actions {
    margin-top: auto;
}

    .modal--generic__action {
        margin-top: 4.25rem;
    }

.modal--generic__scrollable {
    max-height: 17.5rem;
    padding-bottom: 1.25rem;
}

/*------------------------------------*\
  #SPECIAL-CASES
\*------------------------------------*/

.modal--quickview {
    .window-modal__content {
        max-width: 65rem;
    }
}

@include media-breakpoint-up(md) {
    body {
        .cancel-scroll--sm-up & {
            overflow: hidden;
        }
    }

    .window-modal__content {
        width: calc(100% - #{$grid-gutter-width});
        margin: auto;
        transform: translateY(10%);
        @include set-invisible();

        .modal-active & {
            transition-delay: $transition-speed-faster;
            transform: translateY(0);
            @include set-visible();
        }
    }

    .window-modal__close {
        top: .5rem;
        right: .5rem;
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            max-width: 29rem;
            min-height: 21.25rem;
            padding: 3.75rem 1.25rem 1.25rem;
        }
    }

        .modal--generic__title {
            font-size: 1.5rem;
        }

        .modal--generic__subtitle {
            margin-top: 2rem;
        }

    .modal--full-screen {
        .window-modal__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            min-height: 100%;
        }
    }

    /*------------------------------------*\
      #SPECIAL-CASES
    \*------------------------------------*/
    .modal--site-selection {
        .window-modal__content {
            max-width: 38.125rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    html {
        &.cancel-scroll {
            overflow: hidden;
            height: 100%;
        }
    }

    .window-modal__content {
        top: 100%;
        margin-top: auto;

        .modal-active & {
            top: 0;
        }
    }

    .window-modal__close {
        top: 0;
        right: 0;
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            min-height: 18.125rem;
            padding-top: 7.25rem;
            padding-right: 1.25rem;
            padding-left: 1.25rem;
            padding-bottom: 3.75rem;
        }
    }

        .modal--generic__title {
            font-size: 1.25rem;
        }

        .modal--generic__subtitle {
            margin-top: 1.5rem;
        }

    /*------------------------------------*\
      #UTILITY-MODAL-CLASSES
    \*------------------------------------*/

    .modal--sm-full {
        background-color: $white;

        .window-modal__content {
            min-height: 100%;
            padding-bottom: 3.75rem;
        }
    }
}
