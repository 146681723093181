
[type=reset], [type=submit], button, html [type=button] {
    appearance: none;
    -webkit-appearance: none;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    color: currentColor;
}

.btn {
    padding: .4375rem 1rem;
    line-height: 2rem;
    // border: none;
    // outline: none;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .167em;
}

.btn-outline-primary--alt {
    background-color: $white;
}

.facebook-login-btn {
    color: #fff !important;
    border-color: #031e2f;
}