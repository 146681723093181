.product-detail {
    .text-color--matterhorn {
        color: #757575;
    }
}
.pdp--wrapper {
    @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
    }

    h1 {
        font-size: 2rem;
        font-family: $butler;
        letter-spacing: 0.8px;
        color: $darkgreen;
    }

    //Apply only to PDP
    .pdp--media__img {
        @include media-breakpoint-up(md) {
            &:not(:last-child) {
                // margin-bottom: 3.675rem;
                margin-bottom: 0;
            }
        }

        &:hover {
        //    cursor: zoom-in;
        }
    }
}

.pdp--container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        overflow: hidden;
    }
}
.discover-collection {
    position: relative;
    top: 4rem;
    left: 0;
    right: 0;
    font-size: 2rem;
    font-family: Butler;
    letter-spacing: 0.8px;
    color: #031e2f;
    line-height: unset;
    // @include media-breakpoint-up(sm) {
    //     font-size: 1.125rem;
    // }
}

.pdp--media {
    width: 100%;
    @include media-breakpoint-up(md) {
        @include flexrow;
        flex: 1 1 percentage(720/1252);
        max-width: 45.50799%;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 2.25rem;
    }
    @media screen and (min-width: 1280px) {
        position: sticky;
        top: 129px;
    }
    .pdp-main__img {
        flex-grow: 1;
        @media (max-width: 767px) {
            position: relative;
        }
        .zoomIcon {
            display: none;
            @media (max-width: 767px) {
                display: block !important;
                position: absolute;
                z-index: 1;
                bottom: 50px;
                right: 5px;
                font-size: 19px;
                width: 26px;
                text-align: center;
                height: 26px;
                background: #031e2f;
                border-radius: 20%;
            }
            i {
                @media (max-width: 767px) {
                    color: #fff;
                }
            }
        }
    }
}

.pdp-main__img-gallery {
    @include media-breakpoint-down(sm) {
        &:not(.slick-initialized) {
            display: flex;
            overflow: auto;

            .pdp--media__img {
                flex: 1 0 100%;
            }
        }
    }
}

// .pdp--media__thumbnails-wrap {
//     flex: 0 1 60px;
//     margin-right: 48px;

//     @include media-breakpoint-down(sm) {
//         display: none;
//     }
// }

.pdp--media__thumbnails {
    &:focus {
        outline: 2px solid #ccc;
    }
}

.pdp--media__thumbnail {
    width: 60px;
    height: 60px;
    max-width: none;
    margin-bottom: 1.875rem;
    cursor: pointer;
    transition: opacity 213ms ease-in-out;

    &:hover:not(.toggle--active) {
        opacity: 0.75;
    }

    &:not(.toggle--active) {
        opacity: 0.5;
    }
    &:focus {
        outline: 2px solid #ccc;
    }
}

.pdp--product__specs {
    @include media-breakpoint-up(md) {
        flex: 1 1 percentage(470/1252);
        max-width: 45.53994%;
        margin-left: 3.625rem;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container {
        padding-left: 0;
    }

    .prices-add-to-cart-actions {
        padding: 0;
        position: static !important;
        box-shadow: none;
    }
}

.product-attributes__container {
    margin-top: 2rem;
    margin-bottom: 1.25rem;
}

.pdp--swatches {
    width: 100%;
    max-width: 14.5rem;
}

.pdp--value {
    @include flexrow;
    align-items: baseline;

    .value {
        font-size: 0.875rem;
        line-height: 2;
        letter-spacing: 0.6px;
    }
}

.pdp-size__list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .pdp--color__available {
        margin-right: 0;
        max-width: none;
    }
}

.pdp__quantity {
    margin-top: 1.25rem;
}

.quantity-select__container {
    // width: 100%;
    // overflow: hidden;
    position: relative;
    padding: 0.625rem 0;
    max-width: 7.5rem;
    display: flex;

    &:before,
    &:after {
        pointer-events: none;
        z-index: 1;
        position: absolute;
        top: 50%;
        width: 0.0625rem;
        height: 0.5rem;
        background-color: $black;
        content: "";
    }

    &:before {
        right: 0.625rem;
        transform: translate(-0.25rem, -50%) rotate(-45deg);
    }

    &:after {
        right: 0.8rem;
        transform: translate(0.25rem, -50%) rotate(45deg);
    }
}

.quantity-select__label {
    pointer-events: none;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0;
    letter-spacing: 0.11em;
}

.quantity-select__field {
    width: 100%;
    border: 0;
    position: relative;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 4.25rem;

    //To remove button from IE11
    &::-ms-expand {
        display: none;
    }

    //Only reduce font-size on larger screens as mobile devices
    //tend to zoom-in when selecting an input field with a font-size
    //lower than the body's.
    @include media-breakpoint-up(lg) {
        font-size: 0.8125rem;
    }
}

.pdp__utilities {
    margin-top: 0;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 3px;
    @include media-breakpoint-up(lg) {
        margin-top: 9px;
    }
    .fa {
        font-family: 'FontAwesome' !important;
        font-weight: normal !important;
    }
}

.product-availability {
    margin-bottom: 1rem;
}

.add-to-wish-list {
    flex-basis: 100%;
    margin-bottom: 14px;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.range--pricing__range {
    display: block;
    .value {
        font-weight: normal;
    }
}
.pdpCarousel-container {
    max-width: 500px;
    @media (min-width: 768.02px) and (max-width: 991.98px) {
        max-width: 369px;
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
        max-width: 449px;
    }
}
.product-dimens {
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    font-family: Archivo;
}
.pdp-detail {
    margin-top: 3rem;
    margin-right: 4rem;
    .pdp-detail-heading {
        font-family: Archivo;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.3px;
        color: rgb(3, 30, 47);
    }
    .pdp-detail-description {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 23.9333px;
        height: 140px;
        color: rgb(80, 80, 80);
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
/**mobile sticky add to bag button**/
.mobile-fixit {
    @include media-breakpoint-down(sm) {
        position: static;
        right: 15px;
        left: 15px;
        z-index: 8;
        transition: all 0.2s;
        outline: none;

        &.fixed {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            background: $white;
            padding: 15px 15px 25px;
            border-top: 1px solid #d8d8d8;
        }
    }
}

.pdp-main__img-gallery {
    .slick-arrow {
        z-index: 1;
        position: absolute;
        bottom: 0.5rem;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 24px;
        -webkit-appearance: none;
    }
    .slick-slide {
        &:focus {
            outline: auto !important;
        }
    }
    .prev-arrow {
        left: 93%;
    }
    .next-arrow {
        left: 98%;
    }
    @media screen and (max-width: 767px) {
        .prev-arrow {
            left: 90%;
        }
        .next-arrow {
            left: 96%;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        padding: 0.8rem;
        list-style-type: none;
        margin: 0;
    }
}

.quickview-section__media {
    .slick--inner-dots .slick-dots {
       position: relative;
       bottom: 3.675rem;
       li:not(.slick-active) button:after {
        background-color: #9f9f9f;
       }
    }
}
#notifyModal {
    .modal-dialog {
        max-width: 700px;
    }
    .modal-body {
        padding: 2rem;
        .close {
            position: absolute;
            right: 14px;
            top: 8px;
            font-size: 2rem;
            line-height: 1;
        }
        @media screen and (max-width: 500px) {
            padding: 1rem;
        }
    }
    form {
        .form-inner {
            max-width: 500px;
        }
    }
    .discover-collection {
        position: unset;
        top: 0;
        letter-spacing: 0;
        font-size: 1.5rem;
        padding: 30px 0;
        border-top: 0;
        @media screen and (max-width: 500px) {
            font-size: 1.1rem;
        }
    }
    .product-attributes__container {
        margin: 40px 0 30px;

        // @media screen and (max-width: 500px) {
        //     margin: 25px 0;
        // }
    }
    .pdp--swatches .swatch--anchor-color {
        &:first-child {
            margin-left: 0;
        }
    }
    .size-chart {
        display: none;
    }
    .size-anchor__list {
        margin-top: 0;
    }

    .custom-control-label {
        font-size: .7rem;
        text-transform: none;
        font-family: $archivoRegular;
        span {
            display: block;
            font-size: .625rem;
        }
        a {
            text-decoration: underline;
        }
    }

    .btn-primary {
        max-width: 150px;
        width: 100%;
        position: relative !important;
    }

    .radio-option-wraper {
        .reg-radio-box label,
        .after-radio-txt,
        .rd-txt-btm {
            font-size: .7rem;
        }
    }

    .message {
        display: none;
        border-radius: 4px;
        color: #155724;
        background-color: #d4edda;
        border: 1px solid #c3e6cb;
        color: #031e2f;
        padding: 10px;
        font-size: .7rem;
        h4 {
            font-size: 1rem;
        }
    }

    .product-attributes__container{
        .pdp--swatches {
            .color {
                top: -30px;
                left: 0;
            }
        }
    }
}


//New PDP Styles

.background-pdp-fade {
    position: relative;
    overflow: hidden;
    &:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        display:inline-block;
        background-color: rgba(0, 0, 0, 0.51);
        z-index: 2000;
    }
}
.breadcrumb-list {
    @media (max-width: 1279.98px) {
        display: none !important;
    }
}

.breadcrumb-pdp {
    font-family: 'Archivo-ExtraLight';
    font-size: 14px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: 0.28px;
    text-align: left;
    color: #575757;
    text-transform: capitalize;
    padding-left: 23px;
    margin-left: 0;

    @media (min-width: 1280px) {
        margin-bottom: 0;
    }

    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 2.38;
        letter-spacing: 0.32px;
        padding-left: 42px;
    }

    .breadcrumb-list__divider {
        &::after {
            content: '/';
            display: inline-block;
        }

    }
}

.pdp--wrapper {
    &.container {
        padding: 0;
        max-width: none;
    }
    .pdp-main__img {
        .zoomIcon {
            display: none !important;
        }
    }

    .product-name {
        font-family: $didot;
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: $shark;
        margin: 0;
        @include media-breakpoint-up(lg) {
            padding: 9.5px 0;
        }
    }

    .pdp--value {
        flex-direction: column;
        .promotions {
            margin-left: 0;
        }
        .value {
            font-family: $helveticaneue;
            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: $rollingstone;
            margin-right: 10px;
        }
        .strike-through {
            display: inline;
            float: right;
            color: $black;
            .value {
                opacity: 0.3;
            }
        }
    }

    .pdp--product__description {
        @media (min-width: 1280px) {
            margin-bottom: 20px;
        }
    }

    .paragraph--2{
        font-family: $helveticaneue;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.48px;
        text-align: left;
        color: $shark;
    }

    .mobile-show {
        display: block;
        @media (min-width: 1280px) {
            display: none;
        }
    }
    .desktop-show {
        display: none;
        @media (min-width: 1280px) {
            display: block;
        }
    }

    .pdp-variation-wrapper {
        position: relative;

        .size-chart {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .pdp-variation-text {
        font-family: 'Archivo-Regular';
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 0.48px;
        text-align: left;
        color: $shark;
        text-transform: capitalize;
    }
    .quantity-select__container {
        &::before,
        &::after {
            background-color: $white;
        }
    }
    //Quantity Number input
    .pdp-quantity {
        display: flex !important;
        padding-left: 10px;
        z-index: 2;

        .quantity-select {
            display: none !important;
        }
        .quantity-decrease, .quantity-increase, .quantity-select {
            margin-left: 5px;
        }
        input {
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
            &::-webkit-inner-spin-button  {
                -webkit-appearance: none;
            }
        }
        .pdp-quantity__value {
            display: none !important;
            &.selected {
                display: block !important;
            }
        }

        option {
            display: none;
            &:checked {
                display: block;
            }
        }

    }
    .quantity-select {
        display: none !important;
    }

    .quantity-hidden {
        padding-left: 0;
        width: 30px;
        text-align: center;
        margin-left: 5px;
    }
    //.quantity-select__label, .quantity-select {
    .quantity-select__container, .quantity-select__label, .quantity-select {
        font-family: 'Archivo-ExtraLight';
        font-size: 18px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.72;
        letter-spacing: 0.54px;
        text-align: left;
        color: #575757;
        text-transform: capitalize !important;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            font-family: 'Archivo-Thin';
            font-size: 16px;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 0.48px;
        }
        @media (min-width: 1920px) {
            font-size: 20px;
            line-height: 1.55;
            letter-spacing: 0.6px;
        }
        .quantity-select__label {
            color: $black;
            font-weight: 300;
            letter-spacing: 0.54px;
            @media (min-width: 1280px) {
                letter-spacing: 0.48px;
            }
            @media (min-width: 1920px) {
                letter-spacing: 0.6px;
            }
        }

        .quantity-select, .quantity-decrease, .quantity-increase {
            font-weight: 300;
        }
    }

    .quantity-select__field {
        padding-left: 12px;
        width: 30px;
    }

    .quantity-decrease {
        margin-left: 40px;
        @media (min-width: 1920px) {
            margin-left: 50px;
        }
    }

    .quantity-increase, .quantity-decrease {
        &.disabled {
            cursor:not-allowed;
            opacity: 0.5;
        }
    }

    .product-available-registry {
        font-family: $archivo;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.45;
        letter-spacing: 0.03em;
        color: $shark;
        margin-bottom: 32px;
    }

    .product-availability {
        margin-bottom: 0;
        @media (max-width: 1279.98px) {
            position: relative;
        }
    }

    .availability-msg {
        padding: 15px 0;
    }

    .add-to-cart, .btn-store-locator, .notify-button {
        font-family: $helveticaneueMd;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2.11px;
        text-align: center;
        color: $white;
        text-transform: capitalize;
        background-color: $shark !important;
        height: 48px;
        border: none;
        border-radius: 2px;
        &:disabled {
            background-color: #c7c7c7 !important;
            opacity: 1;
            border-color: #c7c7c7;
        }
    }

    .cart-and-ipay {
        &:has(.dw-apple-pay-button) {
            position: relative;
        }

        .apple-pay-btn-wrapper:has(.dw-apple-pay-button),
        &:has(.dw-apple-pay-button):not(:has(.apple-pay-btn-wrapper)) {
            height: 48px;
            position: relative;
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $white;
            gap: 4px;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            font-family: $helveticaneue;
            border-radius: 4px;
            background: $black;
            overflow: hidden;

            &:is(.sticky-bottom-fixed .cart-and-ipay) {
                border-radius: 0;
                margin-top: 1px;
            }
            &:before {
                content: attr(data-pay-with);
                display: block;
            }
            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 100%;
                background: -webkit-named-image(apple-pay-logo-white) center center no-repeat;
            }

            .dw-apple-pay-button.btn,
            .dw-apple-pay-button.btn:hover {
                inset: 0;
                position: absolute;
                z-index: 1;
                padding: 0;
                margin: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                border:none;
            }
        }
    }

    .product-registry-container {
        .myregistry {
            padding: 15px 32px !important;
            font: inherit !important;
            min-width: 0 !important;
            color: inherit !important;
            background: inherit !important;
            height: auto !important;
            margin: 0 !important;

            @include media-breakpoint-up(lg) {
                padding: 15px 0 !important;
            }

            span {
                font-size: inherit !important;
                font-family: $helveticaneueMd;
                font-weight: 500;
                text-transform: unset !important;
                text-decoration: underline;
            }
        }
        &.false {
            display: none;
        }
    }

    .product-registry {
        a {
            &:hover, &:active {
                text-decoration: none;
            }
        }

        .button_text {
            font-family: 'Archivo-ExtraLight' !important;
            font-size: 16px !important;
            font-weight: 200 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56 !important;
            letter-spacing: 0.48px !important;
            text-align: left;
            color: $black !important;
            text-transform: capitalize !important;
            margin-right: 0 !important;
            width: auto !important;
            top: -41px;
            border-bottom: 1px solid #707070;

            @media (min-width: 1280px) {
                font-size: 12px !important;
                line-height: 2.08 !important;
                letter-spacing: 0.36px !important;
                top: 0;
                border-bottom: none;
            }

            @media (min-width: 1920px) {
                bottom: 50px;
                left: 10px;
                font-size: 14px !important;
                line-height: 1.79 !important;
                letter-spacing: 0.42px !important;
            }
        }
    }

    .add-to-cart-wishlist-mobile {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        @media (min-width: 1280px) {
            display: none !important;
        }
        .mobile-fixit {
            order: 1;
            display: block;
            flex: 1 1 50%;
        }
        .prices-add-to-cart-actions {
            order: 3;
            width: 100%;
            display: block !important;
            flex: 1 1 100%;
        }

        > .prices-add-to-cart-actions {
            grid-column: 1 / -1; // 2 columns for applePay
        }

        .add-to-wish-list {
            border: 1px solid $shark;
            border-radius: 2px;
            line-height: 1;
            font-size: 12px;
            padding: 0 12px;
            flex: 1 1 50%;
            height: 48px;
        }
        .add-to-wishlist-mobile-text {
            color: $shark;
            font-family: $helveticaneueMd;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 1.11px;
            text-align: center;
            line-height: 1.2;
        }

        .add-to-cart {
            height: 48px;
            min-height: 48px;
            line-height: 1.2;
            font-size: 12px;
            padding: 0 12px;
            flex: 1 1 50%;
            order: 1;
            width: 100%;
        }

        &.sticky-bottom-fixed {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 11;
            margin: 10px 0 0;
            background-color: $white;
            gap: 0;

            .add-to-cart,
            .add-to-wish-list {
                border-radius: 0;
            }
        }
    }

    .pdp--product__information {
        margin-top: 0;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            margin-top: 20px;
        }
        @media (min-width: 1920px) {

        }
    }

}

//Styling PDP image and content
.pdpCarousel-container {
    //max-width: 100%;

    .slick-list {
        padding: 0 15px !important;
        @media (min-width: 768px) {
            padding: 0 14px !important;
        }
        @media (min-width: 1280px) {
            padding: 0 15px !important;
        }
        @media (min-width: 1280px) {
            padding: 0 !important;
        }
    }
    .slick--simple-dots  {
        .slick-dots {
            margin-bottom: 0;
            padding-bottom: 14px;
            @media (min-width: 768px) {
                padding-bottom: 15px;
            }

            button {
                padding: 5px;
                &::after {
                    //margin: 5px;
                    height: 5px;
                    width: 5px;
                    border-radius: 100px;
                    opacity: 0.4;
                    background: $white;
                }

            }
            .slick-active {
                button {
                    &::after {
                        background: $white;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .pdp--media__img {
        margin: 0 5px;
        video{
            width: 100%;
        }
        @media (min-width: 768px) {
            margin: 0 6px;
        }
        @media (min-width: 1280px) {
            margin: 0;
        }
        @media (min-width: 768px) and (max-width: 1279.98px) {
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        img,
        video {
            @media (min-width: 1280px) {
                &:hover {
                    cursor: url('../images/icons/zoomCursor.svg'), auto !important;
                }
            }
        }
    }

    .qv-pdp--media__img{
        img {
            @media (min-width: 1280px) {
                &:hover {
                    cursor: pointer !important;
                }
            }
        }
    }
}

.pdp--media {
    width: 100%;
    max-width: none;
    margin-bottom: 31px;
    @media (min-width: 768px) {
        margin-bottom: 19px;
    }

    @media (min-width: 1280px) {
        width: 50%;
        margin-bottom: 0;
        flex: auto;
      //  padding-right: 26px;
        //margin-left: 20px;
        margin-left: 0;
        margin-right: 26px;
    }
    @media (min-width: 1920px) {
        margin-right: 40px;

    }

    .pdp-image__thumbs {
        display: none;
        @media (min-width: 1280px) {
            display: block;
            padding-right: 20px;
            width: 16.6%;
            max-height: 546px;
            overflow-y: auto;
        }
        @media (min-width: 1920px) {
            padding-right: 40px;
            width: 15.58%;
        }
    }

    .pdp-image__thumbs-item {
        position: relative;
        .play-icon {
            position: absolute;
            width: 49px;
            text-align: center;
            color: #fff;
            font-size: 26px;
            top: 32%;
            left: 25%;
            z-index: 1;
        }
        @media (min-width: 1280px) {
            margin-bottom: 24px;
        }
        @media (min-width: 1920px) {
            margin-bottom: 30px;
        }
        &.slick-nav--active {
            .pdp-thumb__main-image {
               opacity: 1;
            }
        }
    }
    .pdp-thumb__main-image {
        opacity: 0.3;
    }
    .pdpCarousel-container {
        width: 100%;
        max-width: none;
        @media (min-width: 1280px) {
            width: 83.4%;
        }
        @media (min-width: 1920px) {
            width: 84.42%;
        }
        .slick-dots {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.pdp--product__specs {
    width: 100%;
    max-width: none;
    padding: 0 20px;
    margin: 0;

    @media (min-width: 1280px) {
        width: 50%;
        flex: auto;
        padding-right: 173px;
        padding-top: 48px;
        padding-left: 26px;
    }
    @media (min-width: 1920px) {
        padding-right: 246px;
        padding-left: 40px;
    }
}

.pdp--media {
    @media (min-width: 1280px) {
        padding-top: 48px;
    }
}

.pdp--container {
    flex-direction: column;
    @media (min-width: 768px) {
        margin-bottom: 48px;
    }
    @media (min-width: 1280px) {
        flex-direction: row;
        margin: 0 20px 27px;
    }
    @media (min-width: 1920px) {
        margin: 0 42px 41px;
    }
}

.add-to-wishlist-mobile, .add-to-wishlist-mobile-text {
    display: block;
    @media (min-width: 1280px) {
        display: none;
    }
}

.product-name-utilities {
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    .add-to-wish-list {
        display: none;
        @media (min-width: 1280px) {
            display: block;
            flex-basis: 100%;
        }
    }
}

.pdp--value {
    &.prices {
        padding-top: 0;
        @media (min-width: 1280px) {
            padding-bottom: 15px;
        }
    }
}

.pdp--wrapper {
    .product-attributes__container {
        margin-top: 0px;
        margin-bottom: 0;
        @media (min-width: 768px){

        }
        @media (min-width: 1280px) {
            margin-bottom: 10px;
        }
        @media (min-width: 1920px) {
            margin-bottom: 25px;
        }
        .attribute {
            margin-top: 5px;
            @media (min-width: 768px) {

            }
            @media (min-width: 1280px) {
            }
            @media (min-width: 1920px) {

            }
        }
        .pdp--color__available {
            @media (min-width: 768px) {
                width: 384px;
            }
            @media (min-width: 1280px) {
                width: 360px;
            }
            @media (min-width: 1920px) {

            }
        }
        .pdp-size__list {

            .pdp--swatches {
                width: 100%;
                max-width: 100%;
            }
            @media (min-width: 1280px) {
                padding-right: 0;
                max-width: 100%;
            }
        }
        .pdp--swatches {
            max-width: 100%;
            align-items: flex-end;
            @media (min-width: 768px) {
                max-width: 50%;
            }
            @media (min-width: 1280px) {
                max-width: 100%;
            }
            @media (min-width: 1920px) {

            }

            .color {
                font-family: 'Archivo-Light';
                color: $black;
                font-size: 12px;
                line-height: 2.5;
                top: -35px;
                left: 70px;
                position: absolute;

                @media (min-width: 1920px) {
                    top: -35px;
                    left: 70px;
                }
            }
            .swatch--anchor-color {
                margin: 0 16px 25px 0;
                position: relative;
            }
            .size-anchor__list {
                margin-top: 0;
                flex-wrap: wrap;
                @media (min-width: 768px) {

                }
                @media (min-width: 1280px) {
                    width: 100%;

                }
                // @media (min-width: 1920px) {
                //     width: calc(100% - 135px);
                // }
            }
        }
    }
    .pdp__quantity {
        margin-top: 15px;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
            margin-top: 10px;
        }
        @media (min-width: 1920px) {
            margin-top: 15px;
        }
    }
    .mobile-fixit, .prices-add-to-cart-actions {
        display: none;
        @media (min-width: 1280px) {
            display: block;
        }
    }


    .product-share__list {
        padding: 0 20px;
        border: none;
        @media (min-width: 1280px) {
            width: 123px;
            height: 203px;
            padding: 8px 0;
            border: solid 1px #000;
            top: calc(100% + 10px);
            left: -3px;
            transition: all 0.3s ease-in-out;
            &.toggle--active {
                transition: all 1s ease-in-out;
            }
        }
        @media (min-width: 1920px) {
            width: 178px;
            height: 275px;
            padding: 19px 0;
            top: calc(100% + 10px);
            left: 0;
        }
        @media (max-width: 1279.98px) {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            top: auto;
            z-index: 2001;
            //height: 0;
            //transition: all 1s;
            display: none;
            transform: translateY(100%);
            &.toggle--active {
                animation: share-slide-in 0.5s forwards;
            }

            &:not(.toggle--active) {
                animation: share-slide-out 0.5s forwards;
            }
        }
    }
    .product-share__item {
        font-family: 'Archivo-ExtraLight';
        font-size: 20px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: left;
        color: $black;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin-right: 0 !important;
        padding: 8px 0;
        border-top: 0.5px solid #b5b5b5;
        &:hover {
            opacity: 1;
        }
        @media (min-width: 1280px) {
            padding: 3px 0 3px 20px;
            font-size: 12px;
            line-height: 2.58;
            letter-spacing: 0.36px;
            border-top: none;
        }
        @media (min-width: 1920px) {
            padding: 8px 0 8px 25px;
            font-size: 18px;
            line-height: 1.72;
            letter-spacing: 0.54px;
        }
        .icon {
            display: none !important;
            @media (min-width: 1280px) {
                display: block !important;
                margin-right: 10px;

            }
            @media (min-width: 1920px) {
                margin-right: 15px;
                width: 16px;
                height: 16px;
            }
        }
        .share-link-icon {
            @media (min-width: 1280px) {
                margin-right: 12px;
            }
            @media (min-width: 1920px) {
                margin-right: 15px;
            }
        }
    }

    .product-share__title {
        display: block;
        font-family: 'Archivo-ExtraLight';
        font-size: 20px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: left;
        color: $black;
        padding: 9.5px 0;
        @media (min-width: 1280px) {
            display: none;
        }
        .icon {
            @media (max-width: 1279.98px) {
                float: right;
                padding-top: 3px;
                margin-top: 6px;
            }
        }
    }

    .product-share__trigger {
        &:hover {
            opacity: 1;
        }
    }

    .wishlist-pdp_icon {
        width: 24px;
        height: 24px;
    }
}

.product-share__icon {
    width: 24px;
    height: 24px;
}

.product-detail ~ .global-footer {
    z-index: 10;
    position: relative;
}

@keyframes share-slide-in {
    0% {
        transform: translateY(100%);
    }
      100% {
        transform: translateY(0%);
    }
}

@keyframes share-slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
}

.promotions {
    .callout {
        font-family: 'Archivo-Light';
        color: $darkgreen;

        &:first-child {
            margin-top: 16px;
            @include media-breakpoint-up(lg) {
                margin-top: 12px;
            }
        }
    }
}

.pdp-int {
    .pdp--product__description {
        margin-top: 15px;
    }
}


.btn-store-locator {
    padding: 17px 0;
    @media (min-width: 768px) {
        padding: 20px 0;
    }
    @media (min-width: 1280px) {
        padding: 13px 0;
    }
    @media (min-width: 1920px) {
        padding: 20px 0;
    }
    &.desktop {
        display: none !important;
        @media (min-width: 1280px) {
            display: block !important;
        }
    }
    .icon-shop-store {
        width: 13px;
        height: 21px;
        margin-left: 5px;
        margin-bottom: 2px;
        @media (min-width: 768px) {
            width: 10px;
            height: 16px;
        }
        @media (min-width: 1280px) {
            width: 9px;
            height: 14px;
            margin-right: 5px;
            margin-left: 0px;
        }
        @media (min-width: 1920px) {
            width: 13px;
            height: 21px;
        }
    }
}

.pdpCarousel-container {
    .slick-arrow {
        @media (min-width: 1920px) {
            width: 22px;
            height: 41px;
        }
        @media (min-width: 1280px) and (max-width: 1919.98px) {
            left: 15px;
        }
        background: url('../images/icons/left-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        width: 15px;
        height: 27px;
        opacity: 1;
        left: 30px;
        font-size: 0;
        top: 50%;
        transform: translateY(-50%);

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 45px;
            height: 54px;
            left: -5px;
            top: -15px;
        }
    }
    .slick-next {
        @media (min-width: 1280px) and (max-width: 1919.98px) {
            right: 15px;
        }
        background: url('../images/icons/right-arrow-icon.svg') center center no-repeat;
        background-size: cover;
        right: 30px;
        left: auto;

        &::after {
            content: '';
            left: auto;
            right: -5px;
        }
    }
}

.pdpCarousel-container {
    position: relative;

    .pdp--plus_icon {
        width: 18.4px;
        height: 18.4px;
        position: absolute;
        top: 20px;
        left: auto;
        cursor: pointer;

        @media (max-width: 1279px) {
            right: 40px;
        }

        @media (min-width: 1280px) {
            display: none !important;
        }
    }
}

.qv-socialIcons{
    position: relative;
    right: 0;
}

// size chart popup styles
@keyframes size-guide-slide-in {
    0% {
        transform: translateX(100%);
    }
      100% {
        transform: translateX(0%);
    }
}

@keyframes size-guide-slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
}

@keyframes size-guide-slide-in-mobile {
    0% {
        transform: translateY(100%);
    }
      100% {
        transform: translateY(0%);
    }
}

@keyframes size-guide-slide-out-mobile {
    0% {
        transform: translateY(0%);
    }
      100% {
        transform: translateY(100%);
    }
}

.size-guide-container {
    position: fixed;
    top: 0;
    z-index: 10000;
    right: 0;
    background: $white;
    display: none;
    transform: translateX(100%);

    @media (min-width: 1280px) {
        height: 100vh;
    }


    &.bedding-size-chart {
        width: 100%;
        @media (min-width: 1280px) {
            width: 765px;
            height: 100vh;
        }
        @media (min-width: 1920px) {
            width: 1000px;
        }
    }
    &.bath-size-chart {
        width: 100%;
        @media (min-width: 1280px) {
            width: 824px;
            height: 100vh;
        }
        @media (min-width: 1920px) {
            width: 1080px;
        }
    }

    &.show {
        animation: size-guide-slide-in-mobile 0.2s forwards;
        @media (min-width: 1280px) {
            animation: size-guide-slide-in 0.2s forwards;
        }
    }

    &.hide {
        animation: size-guide-slide-out-mobile 0.2s forwards;
        @media (min-width: 1280px) {
            animation: size-guide-slide-out 0.2s forwards;
        }
    }

    .size-guide-content {
        margin: 0 21px 0 20px;
        @media (min-width: 768px) {
            margin: 0 16px 0 22px;
        }
        @media (min-width: 1280px) {
            margin: 0 26px 0 22px;
        }
        @media (min-width: 1920px) {
            margin: 0 30px;
        }
    }

    .container {
        max-width: none;
        padding: 0;
    }

    .remove-size-guide {
        position: absolute;
        right: 20px;
        top: 16px;
        @media (min-width: 768px) {
            top: 27px;
        }
        @media (min-width: 1280px) {
            right: 24px;
            top: 27px;

        }
        @media (min-width: 1920px) {
            right: 27px;
        }
        .icon {
            @media (min-width: 768px) {
                width: 19px;
                height: 19px;
            }
        }

    }

    .table-container {
        margin-bottom: 0;
        @media (max-width: 767.98px) {
            width: 100%;
            margin: 0;
            overflow-x: auto;
        }
        .table {
            width: 100%;
            table {
                width: 100%;
            }
            > span {
                font-family: 'Archivo-ExtraLight';
                font-size: 18px;
                font-weight: 200;
                display: block;
                text-align: left;
                padding-bottom: 20px;
                @media (min-width: 768px) {
                    font-size: 16px;
                }
                @media (min-width: 1920px) {
                    font-size: 20px;
                }
            }
        }
    }

    .section-head {
        .article {
            margin-left: 0;
            max-width: calc(100% - 35px);
        }

        h2 {
            font-family: 'Butler-Light';
            font-size: 22px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: $black;
            padding: 14px 0 13px;
            margin-bottom: 0;
            border-bottom: 0.5px solid #dbdbdb;
            @media (min-width: 768px) {
                font-size: 28px;
                line-height: 1.07;
                padding: 26px 0;
            }

            @media (min-width: 1920px) {
                font-size: 30px;
                line-height: 1;
            }
        }
    }

    .section-body {
        overflow: auto;
        height: calc(100vh - 150px);
        padding-bottom: 35px;
        padding-top: 15px;
        @media (min-width: 768px) {
            height: calc(100vh - 175px);
            padding-bottom: 45px;
            padding-top: 30px;
        }
        @media (min-width: 1280px) {
            height: calc(100vh - 86px);
            padding-bottom: 80px;
        }
    }

    .section-body {

        .article {
            opacity: 1;
            font-family: 'Archivo-ExtraLight';
            font-size: 18px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.54px;
            text-align: left;
            color: $black;
            max-width: none;
            margin-top: 0;
            padding-bottom: 22px;
            border-bottom: 0.5px solid #dbdbdb;
            @media (min-width: 768px) {
                font-size: 16px;
                line-height: 1.63;
                letter-spacing: 0.48px;
                margin-top: 0px;
                padding-bottom: 57px;
            }
            @media (min-width: 1280px) {
                padding-bottom: 67px;
                margin-top: 0px;
            }
            @media (min-width: 1920px) {
                font-size: 20px;
                line-height: 1.55;
                letter-spacing: 0.6px;
                margin-right: 56px;
                margin-top: 0px;
                padding-bottom: 95px;
            }
            p, span {
                font-size: 18px !important;
                @media (min-width: 768px) {
                    font-size: 16px !important;
                }
                @media (min-width: 1920px) {
                    font-size: 20px !important;
                }
            }

            a {
                text-decoration: underline;
            }
        }

        h3 {
            font-family: 'Butler-Light';
            font-size: 22px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.05;
            letter-spacing: normal;
            text-align: left;
            color: $black;
            margin-top: 20px;
            margin-bottom: 20px;
            @media (min-width: 768px) {
                font-size: 28px;
                line-height: 1.07;
                margin-bottom: 23px;
                margin-top: 32px;
            }
            @media (min-width: 1920px) {
                font-size: 30px;
                line-height: 1;
            }
        }

        .table {
            &.mobile {
                display: none;
            }

            th, strong, td, th span {
                font-size: 8px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: 0.48px;
                text-align: center;
                color: $black;
                @media (min-width: 768px) {
                    font-size: 12px;
                    line-height: 1.67;
                    letter-spacing: 0.36px;
                }
                @media (min-width: 1280px) {
                }
                @media (min-width: 1920px) {
                    font-size: 16px;
                    line-height: 1.25;
                    letter-spacing: 0.48px;
                }
            }
            th , th span{
                font-family: 'Archivo-Light';
                text-align: center;
                font-weight: 300;
                background-color: #f7f7f7;
                text-transform: capitalize;
                padding: 2px 0px;
                &:first-child {
                    text-align: left;
                }
            }
            th, td {
                height: 32px;
                vertical-align: middle;
            }
            strong {
                font-family: 'Archivo-Light';
                text-align: left;
                font-weight: 300;
                text-transform: capitalize;
            }
            td {
                font-family: 'Archivo-Thin';
                text-align: center;
                font-weight: 100;
                padding: 7px 0px;
                border-bottom: 0.5px solid #dbdbdb;

            }
            tr {
                &:first-child {
                    background-color: #f7f7f7;
                    border-radius: 7px;
                }
                td {
                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.hide-on-mb {
    @media screen and (max-width: 1280px) {
        display: none !important;
    }
}
.show-on-mb {
    @media screen and (min-width: 1280px) {
        display: none !important;
    }
    width: 50%;
    .notify-button {
        margin: 0 !important;
        letter-spacing: 0.5px;
        word-spacing: 1px;
        padding: 5px;
        line-height: 1;
    }
}

.sold-out{
    width: 50%;
    margin: 0 !important;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    padding: 5px;
    line-height: 1;
}
.pdp--media {
    .pdp-image__thumbs {
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
            &:hover {
                background-color: #555;
            }
        }
    }
}