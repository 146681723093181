@import "../components/toastMessage";

.product-share {
    position: relative;
}

    .product-share__alert,
    .product-share__list {
        z-index: 5;
        position: absolute;
        top: calc(100% + 1rem);
        left: auto;
        right: 0;
        background-color: $white;
        border: solid .0625rem $grey3;
        white-space: nowrap;

        transition-property: opacity, visibility;
        transition-duration: $transition-speed-faster;
        transition-timing-function: $transition-ease-out;

        &:not(.toggle--active) {
            transition-timing-function: $transition-ease-in;

            @include set-invisible();
        }
    }

    .product-share__trigger {
        padding-top: 0;
    }

    .product-share__list {
        padding: .5rem;
    }

    .product-share__alert {
        padding: 1rem;
    }

        .product-share__item {
            padding: .5rem;

            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

//Important to leave as-is for the share link copy-to-clipboard
//functionality to work.
.share-textarea {
    position: absolute;
    opacity: 0;
    width: 2rem;
    height: 2rem;
}
//New Share Styles
.product-share__list {
    flex-direction: column;
}
