@import "quickView";

.primary-images {
    .carousel {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.product-tile {
    &:hover,
    &:focus-within {
        .quickview {
            @include set-visible();
        }
        .wishlistTile {
            visibility: visible;
        }
    }

    .tile-body {
        margin-top: 1rem;

        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: 1.125em;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: 1.0625em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1em;
            }

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .product-tile__name-link {
            line-height: 1.2;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            padding: 2px;
        }
        &:hover {
            .tile-image--hover {
                @include set-visible();
                transition-timing-function: $transition-ease-out;
            }
        }
    }

    .tile-image--hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-in;

        @include set-invisible();
    }

    .quickview {
        position: absolute;
        bottom: 2.625rem;
        left: 0;
        right: 0;
        width: fit-content;
        min-width: 148px;
        margin: auto;

        @include set-invisible();
        @media screen and (max-width: 768.98px) {
            // width: 90%;
            bottom: .7rem;
        }
    }

    .swatch--anchor-tile {
        margin: 0 .25rem;
    }

    .prices {
        text-align: center;
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

.wishlistTile {
    cursor: pointer;

    .wishlistTile-icon {
        background: url('../images/icons/favorite.svg') center center no-repeat;
        background-size: 24px;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 12px;
        right: 12px;
        display: none;
        
        @media (min-width: 1280px) {
            display: inline-block;
        }

        &:hover {
            background: url('../images/icons/favorite-filled.svg') center center no-repeat;
        }
    }
}

.product-tile {
    .tile-body {
        text-align: left;
        margin-top: 21px;
        margin-bottom: 55px;
        @media (min-width: 768px) {
            text-align: center;
            margin-top: 19px;
            margin-bottom: 44px;
        }
        @media (min-width: 1280px) {
            margin-bottom: 42px;
        }
        @media (min-width: 1920px) {
            margin-top: 23px;
            margin-bottom: 45px;
        }
        .color-swatches {
            min-height: auto;
            line-height: 1;
        }

        .swatch--anchor-tile {
            margin-right: 7px;
            margin-left: 0;
            @media (min-width: 768px) {
               margin: 0 2px;
            }
            @media (min-width: 1280px) {
               
            }
            @media (min-width: 1920px) {
            }
        }
        .more-colors-wrapper {
            position: relative;
            bottom: 3px;
            @media (min-width: 768px) {
                // bottom: 2px;
                bottom: 0;
            }
            @media (min-width: 1280px) {
                
            }
            @media (min-width: 1920px) {
                bottom: 2px;
            }
        }
        .pdp-link {
            margin-top: 18px;
            @media (min-width: 768px) {
                margin-top: 14px;
            }
            @media (min-width: 1280px) {
                margin-top: 13px;
            }
            @media (min-width: 1920px) {
                margin-top: 18px;
            }
        }

        .prices {
            text-align: left;
            padding: 0;
            margin-top: 9px;
            @media (min-width: 768px) {
                text-align: center;
                margin-top: 6px;
            }
            @media (min-width: 1280px) {
                margin-top: 5px;
            }
            @media (min-width: 1920px) {
                margin-top: 10px;
            }
        }

        .product-tile__name-link {
            font-family: $helveticaneue;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.64px;
            text-align: left;
            color: $black;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (min-width: 768px) {
                padding: 0 15px;
                font-size: 14px;
                line-height: 1.53;
                letter-spacing: 0.6px;
                -webkit-line-clamp: 2;
                text-align: center;
            }
        }

        .price, .value, .range {
            font-family: 'Archivo-ExtraLight';
            font-size: 14px !important;
            font-weight: 200 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: 0.42px !important;
            color: #767676 !important;
            @media (min-width: 768px) {
                font-size: 11px !important;
                line-height: 2.09 !important;
                letter-spacing: 0.33px !important;
            }
            @media (min-width: 1280px) {
               
            }
            @media (min-width: 1920px) {
                font-size: 15px !important;
                line-height: 1.53 !important;
                letter-spacing: 0.45px !important;
            }
        }

        .value {
            @media (min-width: 768px) {
                font-size: 12px !important;
            }

            @media (min-width: 1920px) {
                font-size: 12px !important;
            }
        }

        .swatch-circle {
            width: 15px;
            height: 15px;
            border-radius: 1px;
           // border: solid 1px #707070;
            background-color: $white;
            box-shadow: none;
            @media (min-width: 768px) {
                width: 8px;
                height: 8px;
            }
            @media (min-width: 1280px) {
            
            }
            @media (min-width: 1920px) {
                width: 12px;
                height: 12px;
            }
            &.color-value {
                &.selected {
                    &::after {
                        border-radius: 1px;
                        border: solid 1px #757575;
                        left: -2px;
                        top: -2px;
                        width: calc(100% + 4px);
                        height: calc(100% + 4px);
                    }
                }
            }
        }
        .wishlistTile {
            width: 25px;
            height: 25px;
            object-fit: contain;
            
            .wishlistTile-icon{
                top: 20px;
                left: 20px;
                position: absolute;
            }
                
            .wishlist-tile-hover {
                height: auto; 
                width: auto; 
                max-width: 23px; 
                max-height: 23px;
                margin: auto;
            }
        }
            
    }

    .quickview {
        @media (min-width: 1280px) {
            height: 40px;
            border-radius: 2px;
            border: solid 1px $white;
            background-color: rgba(0, 0, 0, 0.10);
            top: 0;
            bottom: 0;
            color: $white;
            font-family: $helveticaneueMd;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.14px;
            text-align: center;
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
                background-color: rgba(0, 0, 0, 0.20);
            }
        }
        @media (min-width: 1920px) {
            // height: 60px;
            font-size: 14px;
            line-height: 1;
            letter-spacing: 2.11px;
        }
        &.desktop-show {
            @media (min-width: 1280px) {
                display: block !important;
            }
        }
        &:focus, &:active {
            background-color: transparent;
            color: $white;
        }
    }

    .image-container {
        a.tile-image-container { 
            &:hover {
                &:after{
                    @media (min-width: 1280px) {
                        content:'';
                        position:absolute;
                        left:0; 
                        top:0;
                        width:100%; height:100%;
                        display:inline-block;
                        background-color: rgba(0, 0, 0, 0.20);
                    }
                }
            }
        }

        .hover-quickview {
            &:after{
                @media (min-width: 1280px) {
                    content:'';
                    position:absolute;
                    left:0; 
                    top:0;
                    width:100%; height:100%;
                    display:inline-block;
                    background-color: rgba(0, 0, 0, 0.20);
                }
            }
        }
    }

    .wishlist-tile-hover {
        
        @media (min-width: 1280px) {
            height: auto;
            width: auto;
            max-height: 18px;
            max-width: 18px;
            margin: auto;
        }
        @media (min-width: 1920px) {
            
            max-width: 23px;
            max-height: 23px;
        }
    }
    
    .wishlist-icon-fill {
        display: none;
    }
}
