@import "slick-carousel/slick/slick.scss";
// @import "slick-carousel/slick/slick-theme.scss";


.slick-dots {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style-type: none;

    button {
        display: block;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        font-size: 0;
    }

    .slick--simple-dots & {
        button {
            padding: .25rem;

            &:after {
                display: block;
                width: .25rem;
                height: .3125rem;
                border-radius: 50%;
                content: '';
            }
        }
    }

    .slick--white-dots & {
        button:after {
            background-color: $alto;
        }

        .slick-active {
            button:after {
                background-color: $matterhorn;
            }
        }
    }

    .slick--inner-dots & {
        position: absolute;
        bottom: 1rem;
        left: 0;
        width: 100%;
    }
}

.slick-arrow {
    z-index: 1;
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 0;
    -webkit-appearance: none;

    &:before {
        font-family: FontAwesome;
    }
}

.slick--side-arrows {
    .slick-arrow {
        &:before {
            font-size: 1.875rem;
        }
    }

    .slick-prev {
        left: .625rem;

        &:before {
            content: '\f104';
        }
    }

    .slick-next {
        right: .625rem;

        &:before {
            content: '\f105';
        }
    }
}

.slick--outside-arrows-desktop {
    @include media-breakpoint-up(lg) {
        padding-right: 2.5rem;
        padding-left: 2.5rem;

        .slick-arrow {
            top: 40%;
        }
    }
}

.slick--side-hovering {
    @include media-breakpoint-up(lg) {
        .slick-arrow {
            top: 0;
            margin-top: center-from-width(934, 294);
            transform: translateY(-50%);
        }

        .slick-prev {
            left: -2.25rem;
        }

        .slick-next {
            right: -2.25rem;
        }
    }

    @include media-breakpoint-between(md, md) {
        padding-right: 2rem;
        padding-left: 2rem;

        .slick-arrow {
            top: 25%;
        }
    }

    @include media-breakpoint-down(sm) {
        .slick-arrow {
            bottom: calc(100% + .75rem);
        }
    }
}

.slick--corner-dots {
    .slick-dots {
        @include media-breakpoint-down(sm) {
            bottom: 2rem;
            right: -6.875rem;

            li button {
                font-size: 34px;
                color: transparent;
                border: 2px solid $white;
                border-radius: 18px;
                box-shadow: 0 0 2px #888;
                opacity: 1;
                width: 10px;
                height: 10px;
                margin-right: 0.625rem;
            }

            .slick-active button{
                background-color: $white;
                border: 2px solid $black;
                border-radius: 18px;
                box-shadow: 0 0 2px #888;
                width: 10px;
                height: 10px;
                top: 2px;

                button:before {
                    opacity: 0;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            position: absolute;
            right: 5%;
            bottom: 2%;
        }
    }
}

.slick-slide {
    outline: none !important;
}