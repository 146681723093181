// Header Banner

.header-banner {
    height: 48px;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 769px) {
        height: 32px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container {
        padding-left: 0;
        padding-right: 0;

        .row {
            margin-right: 0
        }

        .content {
            font-size: 9px;
            line-height: 1.7;
            letter-spacing: .9px;
            text-align: center;
            color: #fff;
            margin-left: auto;
            margin-right: auto;
            padding-left: 42px;
            padding-right: 42px;
            //text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (min-width: 769px) {
                letter-spacing: 1.4px;
                font-size: 10px;
                text-transform: none;
            }
            @media screen and (min-width: 769px) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                text-align: left;
            }

            @media (min-width: 1280px) {
                max-width: calc(100% - 600px);
            }
        }
        .close-button {
            display: none;
        }
    }
}
//new styles
.header-banner {
    height: 44px;
    position: fixed;
    width: 100%;
    //z-index: 10000;
    z-index: 1011;
    background-color: $secondary;
    @media (min-width: 1280px) {
        height: 42px;
    }
    .container {
        max-width: 100%;
        padding: 0 12px;
        @media (min-width: 768px) {
            padding: 0 13.6px;
        }
        @media (min-width: 1280px) {
        
        }
        @media (min-width: 1920px) {
            padding: 0 16.5px;
        }

        .content {
            font-family: $helveticaneue;
            font-size: 12px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 125%;
            letter-spacing: 0.24px;
            text-align: center;
            color: $white;
            padding: 0 16px;
            @media (min-width: 768px) {
                font-family: $helveticaneue;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
                line-height: normal;

            }
        }    
    }

    .close-button {
        padding-bottom: 3px;
        @media (min-width: 768px) {
            padding-bottom: 4px;
        }
        @media (min-width: 1280px) {
        
        }
        @media (min-width: 1920px) {
        
        }
        .close {
            opacity: 1;
            // background-image: url('../images/icons/icon-close-hp.png');
            height: 10px;
            width:10px;
            line-height: 0;
            // background-repeat: no-repeat;
            // background-size: contain;
            margin-top: 2px;
            @media (min-width: 1280px) {
                margin-top: 3px;
            }
        }
    }
}

.menu-active, .modal-shown {
    .header-banner {
        @media (max-width: 1279.98px) {
            z-index: 1;
        }
    }
}

.header-banner-utilities {
    width: auto;
    background: none;
    color: $white;
    font-size: 12px;
    font-size: .75rem;
    display: flex;
    position: absolute;
    right: 20px;

    @media (max-width: 1279.98px) {
        display: none;
    }

    div {
        margin-left: 20px;

        a {
            text-decoration: none;
            letter-spacing: .24px;
            line-height: 125%;

            span {
                text-decoration: underline;
            }
        }

        svg {
            &.icon--location_new, &.icon--language_new {
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }
        }
    }
}