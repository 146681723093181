.subscribe-modal__cookie {
    background: $white;
    min-width: 826px;
    min-height: 421px;

    @include media-breakpoint-down(sm) {
        min-width: 100vw;
        background: $darkgreen;
        color: $white;
    }
}

.subscribe-modal__cookie--body {
    text-align: center;
    padding-top: 4rem;

    @include media-breakpoint-down(xs) {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    strong {
        font-family: $butler;
        color: $darkgreen;
        font-size: 2rem;
        letter-spacing: $letter-spacing-reduce;
        line-height: $font-line-height-normal;
        margin-bottom: 1rem;
    }

    p {
        font-family: $archivo;
        font-size: 0.625rem;
        letter-spacing: $letter-spacing-reset;
        line-height: $font-line-height-xlarge;
        color: $matterhorn;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-left: 5rem;
        padding-right: 5rem;
    }

    @include media-breakpoint-down(sm) {
        strong,
        p {
            color: $white;
        }

        button {
            border: solid 1px $white;
        }
    }

    @include media-breakpoint-down(xs) {
        p {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
        
}
