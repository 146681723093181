
.breadcrumb-list {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    line-height: 2;
    letter-spacing: 1px;
    padding-left: 7px;

    @include media-breakpoint-down(sm) {
        justify-content: center;
    }

    li {
        font-family: $helveticaneue;
        font-size: 12px;
        color: $shark;
        line-height: 2.71;
        letter-spacing: 0.28px;
        text-transform: capitalize;
        @media screen and (min-width: 1920px) {
            font-size: 14px; 
            line-height: 2.38;
            letter-spacing: 0.32px;
        }

        a {
            color: $shark;
        }
    }
}

.breadcrumb-list--clp {
    .breadcrumb-list {
        max-width: 89.25rem;
    }
}

.breadcrumb-list__divider {
        margin: 0 .5rem;
}

.plp-breadcrumbs {
    .breadcrumb-list__divider {
        margin: 0 .5rem;

        &::after {
            content: '/';
            display: inline-block;
        }
    }
}

.home-breadcrumb {
    .breadcrumb-list__divider {
        margin: 0 8px 0 5px;
    }
}