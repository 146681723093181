.slot__container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    .slot__info {
        order: -1;
        padding: 40px 16px 20px;
        min-height: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        background: $white;
        gap: 24px;
        flex: 0 0 auto;
        margin: 0;
        font-family: $helveticaneue;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.45;
        letter-spacing: 0.03em;
    }

    .slot__info-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        align-items: flex-start;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            align-items: center;
            gap: 24px;
        }
    }

    .slot__title {
        margin: 0;
        color: $shark;
        font-family: $didot;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.25;
    }

    .slot__cta {
        flex: 0 0 auto;
        margin-left: auto;
        font-family: $helveticaneueMd;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.45;
        letter-spacing: 0.1em;
        color: $grey5c;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(xl) {
    .slot__container {
        margin: 32px 0;

        .slot__info {
            padding: 48px 64px 48px 48px;
        }

        .slot__title {
            font-size: 26px;
        }
    }
}