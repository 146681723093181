.page-header {
    &:not(.set--transparent-header) {
        .navigation {
            background-color: $white;
        }
    }
}
.navigation__logo-img {
    fill: currentColor;
    width: 100%;
    height: 100%;
}

.navigation__menu-anchor {
    display: inline-block;
    letter-spacing: 1px;
    &.menu-arrow {
        position: relative;
        top: 3px;
        right: -5px;
        height: 15px;
        display: inline-flex;
        align-items: center;
        &.mega-item--active, {
            top: 4px;
        }
        svg {
            width: 18px;
            height: 16px;
            fill: currentColor;
        }
    }
}

.navigation__utility-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation__utility-icon-float {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.navigation__user {
    position: relative;
}

.navigation__user-customer-greeting {
    font-size: 14px;
    padding: 10px 0;
    margin-bottom: 10px;
    display: none;

    .no--flyout & {
        display: block;
    }
}

.navigation__user-name {
    margin-left: 0.25rem;
}

.navigation__user-dropdown {
    z-index: 10;
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: 50vw;
    max-width: 12.5rem;
    padding: 1.25rem;
    background-color: $white;
    border: solid 0.0625rem $grey2;

    &:not(.toggle--active) {
        @include set-invisible();
    }
}

.navigation__user-item {
    &:not(:last-child) {
        margin-bottom: 1.25rem;
    }
}

@media (min-width: 1280px) {

    .header--no-nav {
        padding-top: 2.25rem;
    }

    .page-header {
        position: relative;

        &.set--transparent-header {
            margin-bottom: 1.75rem;
        }
    }

    .navigation-container {
        .set--transparent-header & {
            z-index: 9999;
            position: absolute;
            top: calc(100% + 1.75rem);
            left: 0;
            width: 100%;
            color: $white;
        }
    }

    .navigation {
        padding-bottom: 1.5rem;
        background-color: $white;
    }

    .navigation__logo--floating {
        flex: 0 1 12.5%;
        max-width: 12.5%;

        transform-origin: left top;

        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-default;

        .fixit--active & {
            transform: scale(0.8) translateY(-2.5rem);
        }
    }

    .navigation__utilities {
        position: relative;
        justify-content: flex-end;
        padding-top: 1.25rem;
        padding-bottom: 1.5rem;

        transition: padding-bottom 0s $transition-ease-default;

        &:after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 0.125rem;
            background-color: currentColor;
            content: "";

            transition-property: width, height;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-default;
        }

        .fixit--active &,
        .set--transparent-header & {
            transition-duration: $transition-speed-fast;
        }
    }

    .navigation__utility-item {
        margin-left: 15px;
    }

    .navigation__utility-item--text {
        letter-spacing: 1.5px;

        .toggle--active & {
            @include set-invisible();
        }
    }

    .navigation__utility-icon {
        width: 1.125rem;
        height: 1.125rem;
    }

    .navigation__main {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 1.25rem;

        transition: margin-top 0s $transition-ease-default;

        //Keep transition when resetting back to default state only when
        //header is in transparent mode.
        .fixit--active &,
        .set--transparent-header & {
            transition-duration: $transition-speed-fast;
        }
    }

    .navigation__menu {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 87.5%;
        max-width: 87.5%;
        margin-left: auto;
        padding-left: .9rem;
    }

    .navigation__menu-anchor {
        position: relative;
        padding-bottom: 2.25rem;
        font-size: 0.75rem;
        text-align: right;

        transition: padding-bottom 0s $transition-ease-default;

        &:after {
            position: absolute;
            left: 0;
            bottom: 1.8125rem;
            width: 100%;
            height: 0.125rem;
            background-color: currentColor;
            content: "";

            opacity: 0;
            transition-property: opacity;
            transition-duration: $transition-speed-faster;
            transition-timing-function: $transition-ease-in;
            pointer-events: none;
        }

        &:hover,
        &.mega-item--active {
            text-decoration: none;

            &:after {
                opacity: 1;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-out;
            }
        }

        .fixit--active &,
        .set--transparent-header & {
            transition-duration: $transition-speed-fast;
        }
    }

    .navigation__flyout {
        z-index: 9999;
        position: absolute;
        top: 100%;
        left: -23.5px;
        width: 100vw;
        height: 100vh;
        padding-top: 1.25rem;
        padding-bottom: 3.75rem;
        background-color: $white;
        color: #000;
        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        .mega--active & {
            transition-duration: 0s;
        }

        &:not(.mega-item--active) {
            transition-timing-function: $transition-ease-in;

            @include set-invisible();
        }

        .for-mobile {
            display: none;
        }

        &.mega-item--active {
            display: block !important;
        }
    }

    .navigation__flyout-subnav {
        display: flex;

        &.menu-center {
            justify-content: space-evenly;
        }

        &.shop-all-wrapper {
            position: relative;
            display: block;
            min-width: 220px;
            width: 17%;
            height: 100%;

            .navigation__flyout-subnav-group {
                @media screen and (min-width: 1280px) {
                    padding: 0 !important;
                }

                &:not(:first-child) {
                    .lvl-2 {
                        @media screen and (min-width: 1280px) {
                            padding: 24px 0 0;
                        }
                    }
                }
            }

            .navigation__flyout-subnav-anchor {
                display: inline-block;
                width: 100%;
            }
        }
    }

    .navigation__flyout-subnav-group {
        margin-bottom: 1.75rem;
        min-width: 30%;
        width: 30%;

        &:is(.navigation__flyout-subnav:has(.mega-item--active)>li) {
            .navigation__flyout-subnav-children:has(a:hover) {
                .navigation__flyout-subnav-anchor:hover {
                    text-decoration: underline;
                }
                .navigation__flyout-subnav-anchor:not(:hover) {
                    color: $silver;
                }
            }
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .navigation__flyout-subnav-list {
        margin-top: 0.75rem;
    }

    .navigation__flyout-subnav-item {
        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }
    }

    .navigation__flyout-subnav-group:has(.mega-item--active)
    >.navigation__flyout-subnav-anchor.lvl-2 {
        text-decoration: underline;
        font-weight: bold;
        color: $shark;
    }

    .navigation__flyout-subnav-anchor {
        font-size: 0.75rem;
        transition: all 0.3s;

        &.lvl-2 {
            letter-spacing: 1.1px;
            text-transform: uppercase;
            transition: all 0.1s;

            &:hover {
                text-decoration: underline;
            }
        }

        &.lvl-3 {
            letter-spacing: 0.6px;
        }
    }

    .navigation__media-anchor-list {
        margin-top: 1.625rem;
    }
}

@media (min-width: 1920px) {
    .navigation__flyout {
        left: -41px;
    }
}

@include media-breakpoint-up(xl) {
    .navigation__menu-item {
        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}

.navigation__menu {
    > .navigation__menu-item {
        @include media-breakpoint-up(xl) {
            &:not(:last-child) {
                margin-right: 0;
                padding: 0 20px;
            }

            &:first-child {
                padding-left: 75px;
            }
        }
    }
}

@include media-breakpoint-between(sm, lg) {
    .navigation__menu-item {
        &:not(:last-child) {
            margin-right: 1.5vw;
            display: flex;
            align-items: flex-start;
        }
    }
}

@include media-breakpoint-down(sm) {
    .for-dekstop {
        display: none !important;
    }
}

@media (max-width: 1279.98px) {
    .navigation {
        position: relative;
    }

    .navigation__utility-icon-nonecom {
        margin-right: -21%;
    }

    .navigation__hamburger-trigger {
        position: relative;

        &:focus {
            outline: 2px solid #000;
        }

        &.toggle--active {
            .navigation__hamburger-inner {
                opacity: 0;
            }
        }
    }

    .navigation__utilities {
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .navigation__utility-icon {
        width: 2.25rem;
        height: 2.25rem;
    }

    .navigation__main {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-top: solid 0.0625rem $grey2;
        overflow-x: scroll;
        height: calc(100vh - 116px);

        &:not(.toggle--active) {
            display: none;
        }
    }

    .navigation__menu-item {
        position: relative;
        font-size: 0.875rem;
        letter-spacing: 1px;
        h2 {
            font-size: 0.875rem;
        }
        &:active {
            outline: none;
        }
    }

    .navigation__menu-anchor {
        overflow: hidden;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        background-color: $white;
        font-weight: 600;

        .mega--active &:not(.mega-item--active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;

            transition-timing-function: $transition-ease-in;
        }

        &.mega-item--active {
            padding-left: 3.125rem;
            transition-delay: $transition-speed-fast;

            .navigation__direction-back {
                opacity: 1;
                transition-delay: $transition-speed-medium;
            }

            .navigation__direction-next {
                opacity: 0;
                transition-delay: $transition-speed-fast;
            }
        }
    }

    .navigation__flyout {
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $white;

        transition-property: opacity, visibility;
        transition-duration: $transition-speed-faster;
        transition-timing-function: $transition-ease-out;

        &:not(.mega-item--active) {
            @include set-invisible();

            transition-duration: $transition-speed-faster;
            transition-timing-function: $transition-ease-in;
        }

        .row {
            margin: 0;
        }

        [class*="col-"] {
            padding: 0;
        }
    }

    .navigation__flyout-center {
        padding-right: 0;
        padding-left: 0;
    }

    .navigation__menu-anchor,
    .navigation__flyout-subnav-anchor.lvl-2 {
        display: flex;
        align-items: center;
        position: relative;
        height: 3.875rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        border-bottom: solid 0.0625rem $grey2;
        width: 100%;
    }

    .navigation__flyout-subnav-anchor.lvl-2 {
        padding-right: 2rem;
        padding-left: 3.125rem;
        background-color: $grey1;
        text-transform: uppercase;

        &.mega-item--active {
            .navigation__direction-next {
                transform: rotate(-90deg);
            }
        }
    }

    .navigation__flyout-subnav-list {
        padding: 2rem 2rem 2rem 3.125rem;
    }

    .navigation__flyout-subnav-item {
        &:not(:last-child) {
            margin-bottom: 2.25rem;
        }
    }

    .navigation__direction-next,
    .navigation__direction-back {
        transition: opacity $transition-speed-fast $transition-ease-out;

        @include vertical-align();
    }

    .navigation__direction-next {
        right: 1.5rem;
    }

    .navigation__direction-back {
        left: 1rem;
        opacity: 0;
    }
}

.btn-country-select {
    color: #fff;
    background-color: #031e2f;
    border-color: #031e2f;

    &:hover {
        color: #fff;
    }
}

.hide-on-dekstop {display: none !important;}

@include media-breakpoint-down(sm) {
    .hide-on-dekstop {
        display: block !important;
    }
    .show-on-dekstop {
        display: none !important;
    }
}

.navigation__flyout-subnav {
    .text-color--matterhorn {
        color: #757575;
    }
}

@media screen and (max-width: 1250px) {
    .navigation__menu-anchor {
        font-size: 0.65rem;
    }
}

.navigation__main {
    @media (min-width: 1280px) {
        margin-top: 0;
    }
}

.navigation__utilities {
    padding-top: 16px;
    padding-bottom: 16px;
    @media (min-width: 1280px) {
        justify-content: flex-start;
        padding-top: 40px;
        padding-bottom: 0px;
    }
    @media (min-width: 1920px) {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    &:after {
        width:0;
        height: 0;
        position: static;
        transition: none;
    }
    .fixit--active & {
        &:after {
            height: 0;
            width: 0;
        }
    }
}

.navigation__utility-item {
    @media (min-width: 1280px) {
        margin-left: 15px;
    }
}

@media (min-width: 1280px) {
    .toggle--active {
        .navigation__utility-item--text {
            @include set-visible();//set visible on search click overrided from line 175
        }
    }
}

.navigation__menu {
    @media (min-width: 1280px) {
        justify-content: center;
        max-width: 100%;
        padding-left: 0;
    }
}

.navigation__menu-item {
    &:not(:last-child) {
        @media (min-width: 1280px) {
            margin-right: 25px;
        }
        @media (min-width: 1920px) {
            margin-right: 45px;
        }
    }
}

.navigation__menu-anchor  {
    font-family: 'Archivo-Light';
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.66px;
    text-align: left;
    color: $black;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    @media (min-width: 768px) {
        font-size: 26px;
        letter-spacing: 0.78px;
    }
    @media (min-width: 1280px) {
        font-family: 'Archivo-Light';
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.42px;
        text-align: left;
        color: $black;
        padding-bottom: 0;
        text-transform: none;
    }
    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.48px;
    }

}

.navigation {
    padding-top: 44px;
    position: relative;
    z-index: 999;
    @media (min-width: 1280px) {
        padding-bottom: 0;
        padding-top: 0;
    }
    @media (min-width: 1920px) {
      padding-bottom: 0;
      padding-top: 0;
    }
    > .container {
        padding: 0 20px;
        @media (min-width: 1280px) {
            padding: 0 18.4px 0 23.4px;
            max-width: 100%;
        }
        @media (min-width: 1920px) {
            padding: 0 41px;
        }
    }
    &.no-banner {
        padding-top: 0;
    }
}

.fixit--active  {
    .header-banner {
        @media (min-width: 1280px) {
            transform: translateY(0);
        }
    }
}

.fixit--active.navigation {
    > .container {
        @media (min-width: 1280px) {
            text-align: center;
        }
    }
}

.navigation__account, .navigation__wishlist {
    @media (max-width: 1279.98px) {
        display: none;
    }
}

.navigation__menu-anchor {
    &:after {
        @media (min-width: 1280px) {
            z-index:10000;
            bottom: -5px;
        }
    }
}

.navigation__locator, .navigation__locale  {
        display: none;
        color: $grey5c;
        font: 14px/1.64 $archivoLight;
        letter-spacing: 0.42px;
        text-transform: none;

    @media (min-width: 768px) {
        font: 18px/0.78 $archivoExtraLight;
        letter-spacing: 0.54px;
    }

    @media (min-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font: 14px/1 $archivoExtraLight;
        letter-spacing: 0.42px;
    }

    @media (min-width: 1920px) {
        font: 16px/1.44 $archivoExtraLight;
        font-size: 16px;
        letter-spacing: 0.48px;
    }
}

.navigation__locale, .navigation__search {
    @media (min-width: 1280px) {
        margin-left: 0;
    }
}

.navigation__account {
    @media (min-width: 1920px) {
        margin-left: 22px;
    }
}
.navigation__account ,.navigation__wishlist, .navigation__minicart {
    .navigation__utility-icon {
        width: 24px;
        height: 24px;

        color: $emperor;
        .icon {
            width: 24px;
            height: 24px;
        }
    }
}

.navigation__locale {
    img {
        @media (min-width: 1280px) {
            width: 11px;
            height: auto;
        }
        @media (min-width: 1920px) {
            width: 12px;
            height: auto;
        }
    }
}
.navigation__locator {
    @media (min-width: 1280px) {
        margin-left: 22px;
    }
    @media (min-width: 1920px) {
        margin-left: 28px;
        margin-top: 0;
    }
}

.navigation__hamburger-trigger {
    display: block;
    margin-right: 15px; // tomake logo center small resolution

    .icon--menu_new {
        width: 28px;
        height: 28px;
    }

    @media (min-width: 1280px) {
        display: none;
    }
}

.no-banner {
    .navigation__hamburger-trigger {
        .navigation__hamburger-inner {
            @media (max-width: 1279.98px) {
                top: -1px;
            }
        }
    }
}

.navigation__flyout-subnav-group {
    @media (min-width: 1280px) {
        text-align: left;
    }
}

.navigation__flyout-subnav-anchor {
    &.lvl-2 {
        opacity: 1;
        font-family: 'Archivo-Regular';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: 0.54px;
        text-align: left;
        color: var(--nav-color, $shark);
        text-transform: none;
        height: auto;
        transition: all 0.1s;

        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: 0.6px;
        }

        @media (min-width: 1280px) {
            font-family: 'Archivo-Light';
            font-weight: 300;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 0.48px;
            margin-bottom: 10px;
            display: inline-block;
        }

        @media (min-width: 1920px) {
            font-size: 19px;
            line-height: 1.68;
            letter-spacing: 0.57px;
            margin-bottom: 15px;
        }

        &:hover {
            text-decoration: none;

            @media (min-width: 1280px) {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }

    &.lvl-3 {
        font-family: 'Archivo-ExtraLight';
        font-size: 18px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: 0.54px;
        text-align: left;
        color: $shark;
        @media (min-width: 768px) {
            line-height: 2.78;
        }
        @media (min-width: 1280px) {
            font-size: 14px;
            line-height: 2.14;
            letter-spacing: 0.42px;
        }
        @media (min-width: 1920px) {
            font-size: 19px;
            line-height: 2.11;
            letter-spacing: 0.57px;
        }
    }
}

span.lvl-2 {
    cursor: default !important;
}

.navigation__flyout-subnav-item {
    font-family: 'Archivo-ExtraLight';
    font-size: 18px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: 0.54px;
    text-align: left;
    color: #6c6c6c;

    @media screen and (min-width: 1280px) {
        padding: 0;
        margin: 0;
    }
}

.navigation__menu-item  {
    font-family: 'Archivo-Light';
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.66px;
    text-align: left;
    color: #000;
    @media (min-width: 768px) {
        font-size: 26px;
        letter-spacing: 0.78px;
    }
    @media (min-width: 1280px) {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.42px;
    }
    @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.48px;
    }
}

.icon-location {
    width: 12px;
    @media (min-width: 768px) {
        width: 14px;
        height: auto;
    }
    @media (min-width: 1280px) {
        width: 11px;
    }
    @media (min-width: 1920px) {
        width: 12px;
    }
}

.menu-item.dropdown.country-selector.navigation__menu-anchor  {
    font-family: 'Archivo-Light';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: 0.42px;
    text-align: left;
    color: #5c5c5c;
    text-transform: none;
    padding-bottom: 24px;
    @media (min-width: 768px) {
        font-family: 'Archivo-ExtraLight';
        font-size: 18px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: 0.54px;
        padding-bottom: 29px;
    }
    @media (min-width: 1280px) {
        display: none;
    }
}

.navigation-ch {
    @media (min-width: 1280px) {
        .navigation__logo {
            max-width: 100%;
        }
        .navigation__locale {
            position: absolute;
            left: 0;
        }
        .navigation__locator {
            position: absolute;
            left: 85px;
        }
    }

    .navigation__menu-item {
        &.mega-item--active {
            &:not(.search-show-mobile) {
                @media (max-width: 1279.98px) {
                    height: 137px;
                }

                @media (max-width: 767.98px) {
                    height: 82px;
                }
            }
        }
    }
}

.hidden-class {
    background: none;
    border: none;
    padding: 0;

    @media (min-width: 1280px) {
        display: none;
    }

}

@media (max-width: 1279.98px) {
    .desktop-show {
        display: none !important;
    }

    .hidden-class {
        position: absolute;
        width: 100%;
        height: 58px;
        z-index: 1000;
    }

    .navigation__locale-country {
        margin-left: 5px;
    }

    .navigation__menu-anchor {
        &::after{
            width: 100%;
            height: 58px;
            position: absolute;
            z-index: 10000;
        }
    }
    .mobile-show {
        display: block !important;
        .navigation__search, .navigation__account, .navigation__wishlist, .navigation__minicart {
            display: block;
        }
    }

    .navigation__menu {
        &.mega--active {
            .hidden-class {
                display: none;
            }
            .navigation__menu-item {
                &.mobile-show:not(.search-show-mobile), &.country-selector-menuitem {
                    display: none !important;
                }
            }
        }
    }

    .navigation__flyout{ // added to remove extra scroll due to visibilty hidden
        &:not(.mega-item--active) {
            display: none;
        }
    }
    .navigation__flyout{ // added to remove extra scroll due to visibilty hidden
        &.mega-item--active{
            display: block !important;
        }
    }
    .mega--shown {
        .navigation__menu-item {
            &:not(.mega-item--active) {
                display: none !important;
            }
        }
    }// removing visibilty hidden

    .navigation__flyout-subnav-list{
        &:not(.mega-item--active):not(.lvl-3) {
            display: block;
        }
    }
    .navigation__flyout-subnav-anchor {
        &.lvl-2{
            background-color: $white;
            border-bottom: none;
        }
    }
    .navigation__menu-anchor {
        border-bottom: none;
        height: auto;
    }
    .navigation__flyout-subnav-group {
        border-top: 0.5px solid #dbdbdb;
    }
    .navigation__main {
        top: 0;
        height: calc(100vh);
        background-color: $white;
    }

    .navigation__menu-anchor {
        &.mega-item--active {
            width: 94%;
            padding-left: 0;
            .navigation__direction-back {
                top: -76px;
                left: 0;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    .navigation__menu-item {
        &.mobile-show {
            .shopping-bag-text, .wishlist-text {
                display: block !important;
            }

            .minicart-total {
                .navigation__utility-icon {
                    float: left;
                }
            }

            .navigation__account {
                .navigation__utility-icon {
                    width: auto;
                    justify-content: flex-start;
                }
            }

            .header__search-trigger {
                z-index: 5;
                pointer-events: none;
                top: -47px;
                left: 12px;
            }
        }
    }

    .search-show-mobile {
        &.mega-item--active {
            .navigation__utility-icon {
                display: none;
            }
        }
    }

    .navigation__menu-anchor, .navigation__flyout, .navigation__direction-back {
        transition: none;
    }

    .navigation__menu-anchor{
        &.mega-item--active {
            .navigation__direction-back {
                transition: none;
                transition-delay: 0ms;
            }
        }
    }
    .menu-active {
        overflow: hidden;
        overscroll-behavior-y: contain;
    }
}

@media (max-width: 767.98px) {

    .navigation__flyout {
        width: calc(100vw - 40px);
    }
    .navigation__flyout-subnav-item {
        &:not(:last-child) {
            margin-bottom: 0;
        }
    }
    .navigation__flyout-subnav-list {
        padding: 0 0 10px 0;
    }

    .icon-close-menu {
        width: 16px;
        height: 16px;
    }

    .icon-location {
        width: 12px;
        height: auto;
    }

    .navigation__flyout-subnav-anchor {
        &.lvl-2{
            padding: 16px 0 21px;
        }
    }
    .icons-mega-menu {
        padding: 20px;
        .mobile-img {
            display: block;
        }
        .tablet-img {
            display: none;
        }
    }

    .navigation__menu-item {
        margin: 0 50px 0 20px;
        &.mega-item--active {
            top: -42px;
        }
        &.mobile-show {
            .shopping-bag-text, .wishlist-text, .account-text, .search-text{
                opacity: 0.82;
                font-family: 'Archivo-Regular';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.42px;
                text-align: left;
                color: $black;
                padding-top: 5px;
            }
            .account-text {
                padding-left: 16px;
                padding-top: 0;
            }

            &:not(.mega-item--active) {
                .search-text {
                    padding-top: 1px;
                    span {
                        padding-left: 12px;
                    }
                }
            }

             .wishlist-text {
                padding-left: 36px;
                padding-top: 6px;
            }

            .shopping-bag-text {
                padding-left: 36px;
                padding-top: 3px;
            }

            .navigation__utility-icon {
                height: 20px;
                width: 20px;
                padding: 0;
            }
            .icon {
                height: 20px;
                width: 20px;
            }
            .minicart-quantity {
                left: 7px;
                right: auto;
                top: 0;
            }
        }

    }

    .navigation__menu-anchor {
        &:not(.mega-item--active) {
            padding: 0 0 34px 0;
        }

        &.mega-item--active {
            padding-bottom: 16px;
            padding-top: 64px;
        }
    }

    .mobile-show {
        .navigation__menu-anchor {
            &:not(.mega-item--active) {
                padding: 0 0 23px 0;
            }
        }
        .navigation__minicart {
            margin-top: 35px;
        }
        &.search-show-mobile {
            .navigation__menu-anchor {
                &:not(.mega-item--active) {
                    padding-bottom: 0;
                }
            }

            &.mega-item--active{
                .navigation__flyout-center {
                    margin-top: 0;
                }

                .navigation__flyout {
                    height: calc(100vh - 77px);
                    padding-top: 67px;
                    border-top: none;
                }
                //added here to set search icon position
                .header__search-trigger {
                    left: 0;
                    top: 0;
                }
                .navigation__utility-icon {
                    top: 8px;
                    position: absolute;
                }

                .site-search {
                    position: relative;
                    width: 304px;
                    margin: auto;
                }// till here
            }
        }
    }

    .search-show-mobile {
        &.mega-item--active {
            .search-text {
                font-family: 'Archivo-Light';
                font-size: 22px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.66px;
                text-align: left;
                color: $black;
                text-transform: uppercase;
            }
        }
    }

    .navigation__menu-anchor {
        &.mega-item--active {
            .navigation__direction-back {
                width: 29.7px;
                height: 12.5px;
            }
        }
    }

    .best_seller-section  {
        margin-right: -20px;
    }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
    .navigation__flyout {
        width: calc(100vw - 62px);
        margin-left: -1px;
        background-color: $white;
    }
    .navigation__flyout-subnav-item {
        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    .icon-close-menu {
        width: 28px;
        height: 28px;
    }

    .icon-location {
        width: 14px;
        height: auto;
    }

    .navigation__flyout-subnav-list {
        padding: 0 0 25px 0;
        background-color: $white;
    }
    .navigation__flyout-subnav-anchor {
        &.lvl-2{
            padding: 36px 0 22px;
        }
    }
    .icons-mega-menu {
        padding: 31px 31px 55px 31px;
        .tablet-img {
            display: block;
        }
        .mobile-img {
            display: none;
        }
    }

    .navigation__menu-item {
        margin: 0px 80px 0 31px;
        &.mega-item--active {
            top: -109px;
        }

        &.mobile-show {
            .shopping-bag-text, .wishlist-text, .account-text, .search-text {
                opacity: 0.82;
                font-family: 'Archivo-Regular';
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.54px;
                text-align: left;
                color: $black;
                padding-top: 7px;
            }
            .account-text {
                padding-left: 23px;
                padding-top: 0;
            }
            &:not(.mega-item--active) {
                .search-text {
                    padding-top: 0;
                    span {
                        padding-left: 20px;
                    }
                }
            }
            .wishlist-text {
                padding-left: 49px;
                padding-top: 6px;
            }

            .shopping-bag-text {
                padding-left: 49px;
                padding-top: 3px;
            }

            .navigation__utility-icon {
                height: 25px;
                width: 25px;
                padding: 0;
            }

            .icon {
                height: 25px;
                width: 25px;
            }
            .minicart-quantity {
                left: 10px;
                top: auto;
                bottom: 2px;
            }
        }
    }

    .navigation__menu-anchor {
        &:not(.mega-item--active) {
            padding: 0 0 40px 0;
        }

        &.mega-item--active {
            padding-bottom: 36px;
            padding-top: 94px;
        }
    }
    .mobile-show {
        .navigation__menu-anchor {
            &:not(.mega-item--active) {
                padding: 0 0 32px 0;
            }
        }
        .navigation__minicart {
            margin-top: 48px;
        }
        &.search-show-mobile {
            .navigation__menu-anchor {
                &:not(.mega-item--active) {
                  margin-bottom: 88px;
                    padding-bottom: 0;
                }
            }

            &.mega-item--active{
                .navigation__flyout {
                    height: calc(100vh - 175px);
                    padding-top: 77px;
                }
                // added to set search icon position
                .header__search-trigger {
                    left: 0;
                    top: 0;
                }
                .navigation__utility-icon {
                    top: 5px;
                    position: absolute;
                }

                .site-search {
                    position: relative;
                    width: 520px;
                    margin: auto;
                }
                //till here
            }
        }
    }

    .search-show-mobile {
        &.mega-item--active {
            .search-text {
                font-family: 'Archivo-Light';
                font-size: 26px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.78px;
                text-align: left;
                color: $black;
                text-transform: uppercase;
            }
        }
    }

    .navigation__menu-anchor {
        &.mega-item--active {
            .navigation__direction-back {
                width: 51px;
                height: auto;
            }
        }
    }
    .best_seller-section  {
        margin-right: -31px;
    }
}

@media (min-width: 1280px) {
    .desktop-show {
        display: block !important;
    }
    .mobile-display {
        display: none !important;
    }
    .navigation__flyout {
        padding-right: 23px;
        padding-left: 23px;
        padding-bottom: 0;
        margin-left: 0;

        .navigation__flyout-center {
            border-top: 0.5px solid #dbdbdb;
            &.container {
                padding: 0;
                max-width: 100%;

                @media screen and (min-width: 1280px) {
                    height: 100%;
                }
            }
        }

        .row {
            margin: 0;
            align-items: flex-start;
            flex-wrap: nowrap;

            @media screen and (min-width: 1280px) {
                height: 100%;
            }
        }
    }
    .navigation__flyout-subnav-group {
        padding-top: 35px;
        min-width: auto;
        width: auto;
        flex: 1;
        margin-bottom: 0;
    }

    .menu-center {
        .navigation__flyout-subnav-group {
            flex: none;
        }
    }

    .navigation__flyout-subnav-list {
        margin-top: 0;
        padding-bottom: 40px;
    }

    .navigation__flyout-subnav-item {
        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    .navigation__menu-anchor{
        &:after {
            height: 0.5px;
            bottom: -5px;
            background-color: #292929;
        }
    }
}
.navigation__flyout-bestseller {
    display: none;
    height: calc(100% - 60px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 9px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $rollingstone;
        border-radius: 9px;
    }

    @supports not (scrollbar-width: thin) {
        & {
            scrollbar-color: $rollingstone
        }
    }

    @media (min-width: 1280px) {
        position: absolute;
        left: 580px;
        top: 0;
    }

    &.bestseller-active {
        @media (min-width: 1280px) {
            display: block;
            width: calc(var(--flyout-width) - 628px);
            margin-top: 60px;
            padding: 0 75px;
            border-top: 1px solid $mercury;
        }
    }

    .navigation_bestsellers-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }

    .navigation__bestsellers-title {
        font-size: 18px;
        font-weight: 400;
        color: $shark;
        padding-bottom: 15px;
        margin-bottom: 10px;
        margin-top: 20px;
        text-align: center;
    }

    .dummy-w {
        padding-bottom: 20px;
    }

    .navigation_bestsellers-item {
        .product-tile {
            .image-container {
                padding: 0;
            }

            .quickview {
                display: none !important;
            }

            .tile-image,
            .tile-image--hover {
                @media screen and (min-width: 1280px) {
                    border-radius: 0;
                }
            }

            .tile-body {
                @media screen and (min-width: 1280px) {
                    margin-top: 10px;
                }
            }

            .pdp-link {
                @media screen and (min-width: 1280px) {
                    margin-top: 0;
                }
            }

            .pdp-link {
                @media screen and (min-width: 1280px) {
                    text-align: left;
                    line-height: 1;
                }
            }

            .product-tile__name-link {
                @media screen and (min-width: 1280px) {
                    font-size: 12px;
                    font-weight: 400;
                    color: $shark;
                    line-height: 1;
                    height: 2em;
                }
            }

            .prices {
                @media screen and (min-width: 1280px) {
                    margin-top: 5px;
                    text-align: center;
                    line-height: 1;

                    .price {
                        font-size: 12px !important;
                        line-height: 1 !important;
                        font-weight: 400 !important;

                        .strike-through {
                            display: none !important;
                        }

                        .value {
                            font-size: 12px !important;
                            line-height: 1 !important;
                            font-weight: 400 !important;
                        }

                        .range--pricing {
                            line-height: 1 !important;
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1920px) {
    .navigation__flyout {
        padding-right: 42px;
        padding-left: 42px;
    }

    .navigation__flyout-subnav-group {
        padding-top: 45px;
    }

    .navigation__flyout-subnav-list {
        margin-top: 0;
    }
}

.page-header {
    .navigation__flyout {
        @media (min-width: 1280px) {
            height: calc(100vh - 120px);
        }
    }

    &.fixit--scrolled {
        .navigation__flyout {
            @media (min-width: 1280px) {
                height: calc(100vh - 80px);
            }
        }
    }
}

.menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,.5);
    z-index: 99;
    display: none;
}

.plp-productBadge {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    color: $white;
    margin: 2px;
    padding: 8px 10px 12px;
}

@media screen and (min-width: 1280px) {
    .active-item {
        &:after {
            opacity: 1;
        }
    }
    .navigation__flyout {
        display: none;
    }
}

.background-hidden {
    overflow-x: hidden;

    .navigation__flyout {
        display: block;
    }
}


@media (max-width: 1279.98px) {
    .icon-close-menu {
        position: relative;
        z-index: 1;
    }

    .navigation__flyout{
        width: 100vw;
        &.mega-item--active{
            overflow-y: auto;
            height: calc(100vh - 164px);
            @media (max-width: 767.98px) {
                height: calc(100vh - 200px);
            }
        }
    }
    .navigation__flyout-subnav {
        margin-right: 62px;
        @media (max-width: 767.98px) {
            margin-right: 40px;
            padding-bottom: 50px;
        }
    }
    .navigation__flyout-subnav-group {
        border-bottom: 0.5px solid #dbdbdb;
        border-top: none;
    }
    .navigation__menu-item {
        &.mega-item--active {
            width: calc(100vw - 62px);
            border-bottom: 1px solid #dbdbdb;
            top: 5px;
            position: fixed;
            @media (max-width: 767.98px) {
                top: 15px;
                width: calc(100vw - 40px);
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919.98px) {
    .navigation__utility-icon svg {

    }
    .navigation__minicart {
        .navigation__utility-icon {
            > div {
                line-height: 0;
                position: relative;
            }
        }
    }
    .site-search__trigger-icon svg{
        width: 13.5px !important;
        height: 17.3px !important;
    }
}

.site-search__trigger-icon {
    @media (min-width: 1920px) {
        width: auto;
        height: auto;
    }
}

// Hospitality Page Mega Menu Styles
.hospitality-mega-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 1280px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 39px 0px 39px;
    }

    .navigation__flyout-subnav-group {
        border-bottom: none;

        &:last-child {
            margin-inline-end: 0;
        }
        @media (min-width: 1280px) {
            padding-top: 0px;
            margin-inline-end: 1%;
            width: 25%;
            flex: unset;

            .hospitality-mega-menu-list {
                width: 100%;

                .navigation__flyout-subnav-anchor {
                    width: 100%;
                }
            }
        }

        &:first-child {
            @media (min-width: 1280px) {
                .navigation__flyout-subnav-anchor.lvl-2 {
                    padding-top: 0px;
                }
            }
        }
    }
}

.hospitality-mega-menu-list {
    .mega-menu-img {
        display: none;
        overflow: hidden;

        @media (min-width: 1280px) {
            margin-bottom: 15px;
            overflow: hidden;
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .navigation__flyout-subnav-anchor.lvl-2 {
        font-size: 1.125rem;
        letter-spacing: 0.54px;
        font-family: $archivoExtraLight;
        color: #6c6c6c;
        padding: 10px 0 0;
        margin-bottom: 0px;
        line-height: 1.78;

        @media (min-width: 1280px) {
            font-size: pixelToRem(19);
            letter-spacing: 0.57px;
            color: #000;
            padding: 0px 0px 0px !important;
        }
    }
}

.navigation__menu-anchor {
    display: inline-block;
    &.menu-arrow {
        position: relative;
        top: 4px;
        right: 2px;
        display: none;
        align-items: center;
        transform: rotate(90deg);
        text-decoration: none !important;
        height: 0;
        @media screen and (min-width: 1279px) {
            display: inline-flex;
        }
        svg {
            width: 18px;
            height: 16px;
            fill: currentColor;
            @media screen and (max-width: 1440px) {
                width: 12px;
            }
        }
        &:after {
            display: none;
        }
        &.mega-item--active {
            transform: rotate(-90deg);
            @media screen and (min-width: 1920px) {
                right: 2px;
            }
        }
    }
}

.show-on-dekstop {
    display: none !important;
    @media screen and (min-width: 1279px) {
        display: inline-block !important;
    }
}
.hide-on-dekstop {
    display: none !important;
    @media screen and (max-width: 1279px) {
        display: inline-block !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
    .navigation__menu-anchor {
        &.mega-item--active {
            .navigation__direction-back {
                margin: 0;
                top: 0;
            }
        }
    }

    .mega--active {
        .navigation__menu-item {
            &:not(.mega-item--active) {
                display: none !important;
            }
        }
    }

    .hide-on-dekstop {
        &.navigation__menu-anchor {
            &.mega-item--active {
                height: 30px;
                width: 50px;
                padding: 0;
                top: -63px;
            }
        }
    }

    .mega-item--active {
        .category-name {
            display: none;
        }
        .show-on-dekstop {
            display: block !important;
            padding: 0;
            position: relative;
            top: 64px;
            z-index: 1;
            padding-bottom: 36px;
            border-bottom: 0.5px solid #dbdbdb;
            width: 100%;
        }
    }
    .navigation__menu-item {
        &.mega-item--active {
            &:not(.search-show-mobile) {
                padding-bottom: 0;
                top: 30px;
                height: 130px;
                display: block;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .navigation__menu-anchor {
        &.mega-item--active {
            .navigation__direction-back {
                margin: 0;
                top: 0;
            }
        }
    }

    .mega--active {
        .navigation__menu-item {
            &:not(.mega-item--active) {
                display: none !important;
            }
        }
    }

    .hide-on-dekstop {
        &.navigation__menu-anchor {
            &.mega-item--active {
                height: 20px;
                width: 30px;
                padding: 0;
                top: -40px;
            }
        }
    }

    .mega-item--active {
        .category-name {
            display: none;
        }
        .show-on-dekstop {
            display: block !important;
            padding: 0;
            position: relative;
            top: 35px;
            z-index: 1;
            padding-bottom: 16px;
            border-bottom: 0.5px solid #dbdbdb;
            width: 100%;
        }
    }

    .navigation__menu-item {
        &.mega-item--active {
            &:not(.search-show-mobile) {
                padding-bottom: 0;
                top: 21px;
                height: 77px;
            }
        }
    }
}

// aaded margin borrom marging for user-account
.user-account {
    margin-bottom: 71px;

    @media (min-width: 768px) {
        margin-bottom: 70px;
    }

    @media (min-width: 1280px) {
        margin-bottom: 0;
    }

    @media (min-width: 1920px) {
        margin-bottom: 0;
    }
}

.navigation__utilities {
    .utilities-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (min-width: 1280px) {
            justify-content: center;
        }
        @media (max-width: 1279px) {
            .navigation__logo {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .navigation__hamburger-trigger {
            padding: 0;
            margin: 0;
            width: 28px;
            height: 28px;
        }
    }

    .utilities-center {
        display: none;

        a {
            width: 101.8px;
            height: 31.2px;
        }
        @media (min-width: 1280px) {
            display: flex;
        }
    }

    .utilities-right {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    &.row {
        margin: 0 !important;
        min-height: 70px;
        .col-4 {
            padding: 0 !important;
            position: unset;
        }
    }

    @media (min-width: 1280px) {
        .utilities-center {
            .navigation__menu-item {
                a {
                    width: unset;
                    height: unset;
                }

                button.menu-arrow {
                    display: none;
                }
            }
        }
    }
}

body.menu-active .navigation__utilities {
    .utilities-center {
        display: block;
    }
}

.worldoffrette-mega-wrapper {
    @media (min-width: 1280px) {
        justify-content: space-between !important;
    }
    .flyout-img {
        @media (min-width: 1280px) {
            width: 19%;
            padding: 39px 0;
            flex: none;
        }
        img {
            display: none;
            @media (min-width: 1280px) {
                display: block;
            }
        }
    }

    .navigation__flyout-subnav-group {
        border-bottom: none;
    }

    .navigation__flyout-subnav-anchor{
        &.lvl-2 {
            font-size: pixelToRem(18);
            letter-spacing: 0.54px;
            font-family: $archivoExtraLight;
            color: $grey-6C;
            padding: 10px 0 0;
            margin-bottom: 0px;
            line-height: 1.78;

            @media (min-width: 1280px) {
                margin-top: 0;
                font-size: pixelToRem(19);
                letter-spacing: 0.57px;
                line-height: 2.11;
                color: $black;
                padding: 0 !important;
            }
        }
    }
}

.menu-hbyfrette {
    .open-link {
        width: 17px;
        height: 17px;
        margin-bottom: 7px;
        margin-left: 4px;

        @media (min-width: 768px) {
            width: 19px;
            height: 19px;
            margin-bottom: 5px;
            margin-left: 4px;
        }

        @media (min-width: 1280px) {
            width: 10px;
            height: 10px;
            margin-bottom: 3px;
        }
        @media (min-width: 1920px) {
            margin-bottom: 1px;
        }
    }
}

// Korean Sites
.kr-header {
    .kr-frette-logo {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;

        .navigation__logo {
            width: 101.8px;
            height: 31.2px;
        }
    }

    @media (min-width: 1280px) {
        .kr-frette-logo {
            width: calc(100% - 280px);
            justify-content: center;
            align-items: center;
            display: flex;

            .navigation__logo {
                width: 150.3px;
                height: 46.1px;
            }
        }
    }
}

.z-index-1 {
    z-index: 1 !important;
}

/*New Redesign Styles */
@media (min-width: 1280px) {
    .utilities-left {
        &.col-4 {
            width: auto;
            max-width: unset;
            flex: 0 1 auto;
        }
    }
    .utilities-center {
        &.col-4 {
            width: auto;
            max-width: unset;
            flex: 1 1 auto;
        }
    }
    .utilities-right {
        &.col-4 {
            width: auto;
            max-width: unset;
            flex: 0 1 auto;
            margin-right: 30px;
        }
    }
}

#shop-all {
    @media (min-width: 1280px) {
        pointer-events: none;
        cursor: pointer;
    }
}

svg.navigation__logo-img {
    max-width: 134px;
    max-height: 40px;

    @media (min-width: 1280px) {
        margin: 10px 10px 10px 20px;
        max-width: 136px;
    }
}

.navigation {
    &.toggle--active {
        svg.navigation__logo-img {
            fill: inherit;
        }
    }
}

.registration-icon {
    color: $emperor;
}

.transparent-header:not(.scrolled) {
    &.fixit-element .navigation {
        background: none;
        position: absolute;
        width: 100%;
        color: $white;
        transition: 0.25s;
    }
    .navigation__menu-anchor {
        color: $white;

        &::after {
            background-color: $white !important;
        }
    }
    svg.search-icon {
        fill: $white !important;
    }
    .site-search__field {
        border-color: $white;

        @media (min-width: 1280px) {
            color: $white;
        }

        &::placeholder {
            @media (min-width: 1280px) {
                color: $white;
            }
        }
    }
    .registration-icon {
        color: $white;
    }
    .navigation__wishlist .navigation__utility-icon {
        color: $white !important;
    }
    .navigation__minicart .navigation__utility-icon {
        color: $white;
    }
    .navigation__minicart .shopping-bag-text {
        color: $white;
    }
}

.fixit--active {
    svg.navigation__logo-img {
        max-width: 136px;
    }
}

@media (max-width: 1279px) {
    .navigation__search {
        .site-search {
            .site-search__form {
                margin: 0 !important;
                max-width: unset;
                position: relative;

                label {
                    right: 29px;
                    width: 100%;
                }
            }
        }
        .suggestions-wrapper {
            display: block;

            .suggestions-container {
                padding: 24px;
            }
        }
    }

    .menu-active {
        .navigation.toggle--active {
            padding-top: 24px;

            .logo-utility {
                .navigation__logo {
                    z-index: 1;
                    transform: none;
                    left: 16px;
                    top: 40px;
                }
            }

            .utilities-right {
                .navigation__account,
                .navigation__wishlist {
                    display: block;

                    .account-text {
                        display: none !important;
                    }
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .icons-mega-menu {
                padding: 7px;
                display: block !important;

                .icon-close-menu {
                    width: 10px;
                    height: 10px;
                    display: block !important;
                }
            }

            .navigation__menu {
                margin-top: 70px;
                padding: 24px 32px;
            }

            .utilities-center {
                a {
                    width: auto;
                    height: auto;
                }

                .navigation__menu-item {
                    font-size: 0;
                    padding: 16px 0;
                    margin: 0;

                    &:not(.utility-menu-item) {
                        border-bottom: 1px solid $mercury;
                    }

                    &.has--flyout:after {
                        background-image: $svg-arrow-right;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        content: "";
                        background-repeat: no-repeat;
                        transform: translateY(-50%);
                    }

                    .navigation__menu-anchor,
                    &.store-locator a,
                    &.locale-selector a {
                        text-align: left;
                        color: $shark;
                        font-size: 18px;
                        font-family: $helveticaneue;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 125%;
                        letter-spacing: 0.36px;
                        padding: 0;
                        text-transform: none;
                    }

                    &.utility-menu-item {
                        .navigation__menu-anchor {
                            font-size: 14px;
                            letter-spacing: 0.28px;
                        }
                        &.store-locator a,
                        &.locale-selector a {
                            font-size: 14px;
                            letter-spacing: 0.28px;
                            display: flex;
                            align-items: center;
                            color: $shark;
                            text-transform: none;

                            .navigation__locale-country {
                                margin: 0;
                            }
                            .icon--language_new {
                                width: 22px;
                                height: 21px;
                            }
                            .icon--location_new {
                                height: 21px;
                            }

                            > span {
                                padding: 6px 0 0 12px;
                            }
                        }

                        &:first-of-type {
                            margin-top: 16px;
                        }

                        .navigation__utility-icon {
                            justify-content: left;
                            align-items: center;

                            .account-text {
                                padding: 6px 0 0 12px;
                                text-align: left;
                                color: $shark;
                                font-size: 14px;
                                font-family: $helveticaneue;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 125%;
                                letter-spacing: 0.36px;
                                text-transform: none;
                            }
                        }
                    }
                }
            }

            &.mega--active {
                .logo-utility {
                    display: none;
                }

                .utilities-right .navigation__utility-item {
                    display: none;

                    &.navigation__account, &.navigation__minicart {
                        display: block;
                    }
                }

                .navigation__menu {
                    margin: 0;
                    padding: 0;
                }

                .navigation__menu-item {
                    &.has--flyout {
                        &.mega-item--active {
                            position: relative;
                            top: unset;
                            left: unset;
                            height: unset;
                            padding-left: 32px;
                            width: 100%;
                        }
                        .navigation__menu-anchor {
                            padding-left: 30px;
                            position: relative;

                            &:before {
                                background-image: $svg-arrow-left;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 15px;
                                height: 17px;
                                background-size: contain;
                                content: "";
                                background-repeat: no-repeat;
                                transform: translateY(-50%);
                            }
                        }
                        &:after {
                            background: none;
                            position: absolute;
                            width: 100%;
                            display: inline-block;
                            height: 1px;
                            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
                            z-index: 1;
                            top: unset;
                            left: 0;
                            bottom: 0;
                        }
                    }

                    .navigation__flyout.mega-item--active {
                        width: 100%;
                        padding-left: 32px;

                        .navigation__flyout-subnav {
                            margin: 0;
                            padding: 0;
                            margin-right: 32px;
                            width: 100%;

                            .navigation__flyout-subnav-group {
                                border: none;

                                .navigation__flyout-subnav-anchor {
                                    padding: 16px 0;
                                    width: 100%;
                                    display: inline-block;
                                    cursor: pointer !important;
                                    border-bottom: 1px solid $mercury;

                                    font-family: $helveticaneue;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 125%; /* 17.5px */
                                    letter-spacing: 0.28px;
                                    // text-transform: uppercase;
                                    color: $shark;

                                    &:after {
                                        background-image: $svg-arrow-right;
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        width: 20px;
                                        height: 20px;
                                        content: "";
                                        background-repeat: no-repeat;
                                        transform: translateY(-50%);
                                    }
                                }

                                .navigation__flyout-subnav-list {
                                    display: none;
                                }

                                &.mega-item--active {
                                    .navigation__flyout-subnav-anchor:after {
                                        background-image: $svg-arrow-right;
                                    }

                                    .navigation__flyout-subnav-list {
                                        display: block;
                                        margin: 8px 0 16px;
                                        padding: 0;

                                        font-family: $helveticaneue;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 125%; /* 17.5px */
                                        letter-spacing: 0.28px;
                                        text-transform: uppercase;
                                        color: $shark;

                                        .navigation__flyout-subnav-item {
                                            padding: 8px 0;
                                            font-size: 0;
                                        }

                                        .navigation__flyout-subnav-anchor {
                                            font-size: 14px;
                                            text-transform: none;
                                            font-family: $helveticaneue;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 125%; /* 17.5px */
                                            letter-spacing: 0.28px;
                                            color: $shark;
                                            padding: 0;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.navigation__menu-item {
    .icon--person_new {
        fill: #212529;
    }
}

.third-lvl-dummy-cat {
    display: none;
}

@media (min-width: 1280px) {
    li.navigation__menu-item button.navigation__menu-anchor.lvl-1 {
        display: block;
    }
    li.navigation__menu-item .menu-arrow {
        display: none !important;
    }

    .navigation__menu-item {
        &.menuitem-world-of-frette,
        &.menuitem-hospitality {
            .navigation__flyout.mega-item--active {
                padding-right: 48px !important;

                .navigation__flyout-subnav {
                    display: flex !important;
                    flex-wrap: wrap;
                    padding: 0;
                    justify-content: flex-start !important;
                    width: 100% !important;

                    li.navigation__flyout-subnav-group {
                        width: 33.3%;
                        padding: 8px !important;
                        margin: 0;

                        @media screen and (min-width: 1920px) {
                            width: 25%;
                        }

                        .navigation__flyout-subnav-anchor {
                            width: 100%;
                        }

                        span.img-wrap,
                        .mega-menu-img {
                            max-width: 100%;
                            display: block;
                            overflow: hidden;
                            margin: 0 !important;

                            img {
                                width: 100%;
                                aspect-ratio: 3 / 2;
                            }

                            & + span {
                                @media (min-width: 1280px) {
                                    display: inline-block;
                                    margin-top: 10px;
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #mainHeader {
        .navigation__utilities {
            position: unset;

            .fixit--active & {
                padding-top: 0;
            }

            .utilities-center {
                position: unset;

                #mainMenu {
                    position: unset;
                    height: unset !important;

                    .navigation__menu {
                        min-height: 79px;
                        align-items: stretch;

                        .navigation__menu-item {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .navigation__flyout.mega-item--active {
                        left: 0;
                        padding: 0;
                        padding-bottom: 0;

                        @media screen and (min-width: 1280px) {
                            width: 85%;
                        }
                        @media screen and (min-width: 1440px) {
                            width: 75%;
                        }
                        @media screen and (min-width: 1600px) {
                            width: 67%;
                        }
                        @media screen and (min-width: 1920px) {
                            width: 56%;
                        }

                        .navigation__flyout-subnav {
                            padding: 15px 0 48px 48px;
                            background-color: $alabaster;

                            .navigation__flyout-subnav-group {
                                padding: 24px 0 0;

                                .navigation__flyout-subnav-anchor {
                                    cursor: pointer !important;
                                }

                                &:first-child {
                                    padding-top: 0;
                                }

                                .navigation__flyout-subnav-anchor.lvl-2 {
                                    margin: 0;
                                    width: 100%;
                                }

                                .navigation__flyout-subnav-list {
                                    display: none;

                                    &.mega-item--active {
                                        display: block;
                                        position: absolute;
                                        left: 100%;
                                        top: 60px;
                                        // background-color: $alabaster;
                                        padding: 15px 48px;
                                        height: calc(100% + 40px);
                                        width: 360px;
                                        border-top: 1px solid $mercury;

                                        .navigation__flyout-subnav-item {
                                            font-family: $helveticaneue;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 125%;
                                            letter-spacing: 0.28px;

                                            &:first-child {
                                                border-top: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .third-lvl-dummy-cat {
                            &.dummy-cat-active {
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 100%;
                                width: 360px;
                                height: 60px;
                                font-size: 18px;
                                font-weight: bold;
                                padding: 20px 10px 10px 48px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .navigation__search {
                .suggestions-wrapper {
                    left: 0;
                    top: unset;
                    margin-top: 24px;

                    .suggestions-container {
                        padding: 0 24px 24px;

                        .reset-close-icon {
                            position: absolute;
                            display: block;
                            top: 0;
                            right: 48px;
                            background-size: 10px 10px;
                            width: 10px;
                            height: 10px;
                            cursor: pointer;
                        }

                        .searched-product-slider {
                            .slider-img {
                                margin: 0;

                                a {
                                    max-width: 255px;
                                    max-height: 170px;
                                    display: block;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            .slider-info {
                                text-align: left;
                                padding: 16px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .navigation__flyout.mega-item--active {
        .navigation__flyout-center {
            border: none;
        }
    }
}

.navigation__flyout-subnav-item {
    position: relative;
    margin-bottom: 16px;

    @media screen and (min-width: 1280px) {
        margin-bottom: 0;
    }
}

.navigation__flyout-subnav-toggle {
    display: none;

    &:checked {
        & + .navigation__flyout-subnav-label {
            .navigation__flyout-subnav-icon {
                transform: rotate(180deg);
            }
        }

        & ~ .navigation__flyout-subnav-children {
            max-height: 1000px;
            opacity: 1;
            transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
        }
    }
}

.navigation__flyout-subnav-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 8px;
    border-bottom: 1px solid $mercury;
    line-height: 1;

    @media screen and (min-width: 1280px) {
        border-bottom: 0;
    }
}

.navigation__flyout-subnav-anchor {
    &.lvl-3 {
        font-size: 14px;
        color: $shark;
        text-decoration: none;
        text-transform: uppercase;

        @media screen and (min-width: 1280px) {
            text-transform: none;
        }
    }

    &.lvl-4 {
        font-size: 14px;
        color: $shark;
        text-decoration: none;
        line-height: 1;

        @media screen and (min-width: 1280px) {
            color: $shark;
        }

        &:hover {
            color: $shark;
        }
    }
}

.navigation__flyout-subnav-icon {
    width: 24px;
    font-size: 24px;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
        font-family: "FontAwesome";
        content: '\f107';
    }
}

.navigation__flyout-subnav-children {
    max-height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    text-transform: none;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    @include media-breakpoint-up(xl) {
        margin: 0 24px;
    }
}

.navigation__flyout-subnav-child {
    font-size: 14px;
    padding: 8px 0;
    margin: 0 0 8px 0;
    line-height: 1;

    @include media-breakpoint-up(xl) {
        margin: 0 0 4px 0;
    }

    &:last-child {
        margin-bottom: 24px;
    }
}

.navigation__virtual-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    background: $white;
    z-index: 10;
    padding: 10px 32px 0;
    display: none;

    &.open {
        display: block;
        @media (min-width: 768px) and (max-width: 1279.98px) {
            height: calc(100vh - 80px);
        }
    }
}

.navigation__flyout-custom-subnav {
    display: none;

    @media screen and (min-width: 1280px) {
        display: block;
        width: 100%;
        padding: 48px;

        .top-nav-collection__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 5px;
            grid-row-gap: 30px;
        }

        .top-nav-collection__item-image {
            display: block;
            margin-bottom: 16px;
        }

        .top-nav-collection__item-title {
            font-size: 16px;
            font-weight: 400;
            color: $shark;
        }

        .third-level-active & {
            display: none;
        }
    }
}

.mobile-show {
    display: block;

    @media screen and (min-width: 1280px) {
        display: none !important;
    }
}
