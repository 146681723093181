
.link,
.link--primary {
    text-transform: uppercase;
    letter-spacing: .9px;

    transition-property: opacity;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    &:hover {
        cursor: pointer;
        opacity: 1;
        text-decoration: none;
    }
}

.link {
    &:hover {
        text-decoration: underline;
    }
}

.link--primary {
    display: inline-block;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: .9px;
}

.cta--underline {
    padding-bottom: .25rem;
    border-bottom: solid 1px currentColor;
    letter-spacing: .125em;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.cursor--pointer {
    cursor: pointer;
}