.product-quickview {
    max-width: 54.875rem;

    @include media-breakpoint-up(md) {
        // padding-top: 5rem !important;
        // padding-bottom: 6.25rem !important;
        padding: 80PX !important;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 5.5rem;
    }
}

.quickview-section__media {
    @include media-breakpoint-down(sm) {
        margin-right: -1rem;
        margin-left: -1rem;
    }
}


.quickview-section__media {
    margin-right: 26px;
}

.quickview-section__info { 
    @include media-breakpoint-up(md) {
        // padding-left: 4rem;
        padding-left: 26px;
        border-left: 1px solid #dbdbdb;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 2rem;
    }
    [data-attr="size"] {
        .pdp--color__available {
            max-width: none;
            margin-right: 0;
        }
    }
}
