
.site-select {
    @include media-breakpoint-up(md) {
        max-width: 28.25rem;
        padding-top: 5.25rem;
        padding-bottom: 6.25rem;
    }

    @include media-breakpoint-down(sm) {
        max-width: 19.625rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

    .site-select__subtitle {
        max-width: 22rem;
        margin: 1rem auto 0;
        color: #757575;
    }

    .site-select__fields {
        margin-top: 2rem;
    }

        .site-select__field-row {
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }

    .site-select__actions {
        margin-top: 2.625rem;
    }

    .site-select__input-field {
        @include media-breakpoint-up(md) {
            text-transform: uppercase;
            font-size: .625rem;
        }
    }