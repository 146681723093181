@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";

isapplepay.btn {
    display: none !important;
}

.dw-apple-pay-button.btn {
    &, &:hover {
        padding: 0.5em 1em;
        margin: 0;
        margin-top: 15px;
        border-radius: 0;
        background-size: auto 20px;
    }
}

.checkout-and-applepay .btn {
    margin: 1%;
}

.checkout-continue .checkout-and-applepay {
    @include media-breakpoint-only(xs) {
        padding: 0 $grid-gutter-width / 2 1.625em;
    }
}


.add-to-cart,
.add-to-cart-global {
    border-radius: 8px;
    background-color: #01070b !important;
    font-size: 9px;
    letter-spacing: 1.58px;
    line-height: 0.89;
    height: 40px;
    font-family: 'Archivo-Medium';
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(xs) {
        max-width: 100%;
    }
}

.cart-and-ipay {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .btn {
        width: 100%;
        max-width: 100%;
    }

    &.set--stacked {
        flex-direction: column;
    }

    &:not(.set--stacked) {
        @include media-breakpoint-up(lg) {
            .btn {
                flex: 1 1 50%;
            }

            .btn + .btn {
                margin-left: 1rem;
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;

            .btn {
                flex: 1 1 100%;
            }

            .btn + .btn {
                margin-top: 1.25rem;
            }
        }
    }

    .order-terms-div {
        display: none;
    }
}

.applepay-tab-wrapper {
    display: none;
}

.dw-apple-pay-button.set--stacked {
    &, &:hover {
        margin-top: 1.25rem;
    }
}

body.apple-pay-enabled .applepay-tab-wrapper {
    display: block;
}

.custom-control-apay {
    display: none;
    margin: 15px auto 0;

    &.apay-initiated {
        display: block;
    }

    .custom-control-label {
        a {
            text-decoration: underline;
        }
    }
}

.apple-pay-btn-wrapper {
    position: relative;

    &:not(.terms-accepted) {
        .apple-pay-dummy-wrapper {
            position: absolute;
            z-index: 999;
            height: 100%;
            width: 100%;
        }
        
        .dw-apple-pay-button {
            pointer-events: none;
        }
    }
}

.apple-pay-btn-wrapper {
    + .order-terms-div {
        display: none;
    }
}

.dditional-payments-wrapper {
    .custom-control-apay {
        label {
            font-size: 10px;
        }
    }
}
